import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ProductList } from 'src/app/models/product-ui.model';

@Component({
  selector: 'app-product-card-info',
  templateUrl: './product-card-info.component.html',
  styleUrls: ['./product-card-info.component.scss'],
})
export default class ProductCardInfoComponent implements OnInit, OnChanges {

  @Input() product: ProductList;
  @Input() hover = false;
  @Input() smallLabels = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {

  }
}
