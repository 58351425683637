// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 30px;
}

.alert-icon {
  font-size: 35px;
}

h1 {
  font-size: 22px;
  font-weight: 500;
}

h2 {
  color: #949494;
  font-size: 16px;
  font-weight: 400;
}

.close {
  min-height: auto;
}
.close::part(native) {
  padding: 0;
}
.close ion-icon {
  color: black;
  font-size: 20px;
}

.delete-button > ion-icon {
  font-size: 22px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbmZpcm0tbW9kYWwtY2FydC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGFBQUE7QUFDSjs7QUFFQTtFQUNJLGVBQUE7QUFDSjs7QUFFQTtFQUNJLGVBQUE7RUFDQSxnQkFBQTtBQUNKOztBQUVBO0VBQ0ksY0FBQTtFQUNBLGVBQUE7RUFDQSxnQkFBQTtBQUNKOztBQUVBO0VBRUksZ0JBQUE7QUFBSjtBQUdJO0VBQ0ksVUFBQTtBQURSO0FBSUk7RUFDSSxZQUFBO0VBQ0EsZUFBQTtBQUZSOztBQU1BO0VBQ0ksZUFBQTtBQUhKIiwiZmlsZSI6ImNvbmZpcm0tbW9kYWwtY2FydC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jb250YWluZXIge1xuICAgIHBhZGRpbmc6IDMwcHg7XG59XG5cbi5hbGVydC1pY29uIHtcbiAgICBmb250LXNpemU6IDM1cHg7XG59XG5cbmgxIHtcbiAgICBmb250LXNpemU6IDIycHg7XG4gICAgZm9udC13ZWlnaHQ6IDUwMDtcbn1cblxuaDIge1xuICAgIGNvbG9yOiAjOTQ5NDk0O1xuICAgIGZvbnQtc2l6ZTogMTZweDtcbiAgICBmb250LXdlaWdodDogNDAwO1xufVxuXG4uY2xvc2Uge1xuXG4gICAgbWluLWhlaWdodDogYXV0bztcbiAgICBcblxuICAgICY6OnBhcnQobmF0aXZlKSB7XG4gICAgICAgIHBhZGRpbmc6IDA7XG4gICAgfVxuXG4gICAgaW9uLWljb24ge1xuICAgICAgICBjb2xvcjogYmxhY2s7XG4gICAgICAgIGZvbnQtc2l6ZTogMjBweDtcbiAgICB9XG59XG5cbi5kZWxldGUtYnV0dG9uID4gaW9uLWljb24ge1xuICAgIGZvbnQtc2l6ZTogMjJweDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/confirm-modal-cart/confirm-modal-cart.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,cAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEA;EAEI,gBAAA;AAAJ;AAGI;EACI,UAAA;AADR;AAII;EACI,YAAA;EACA,eAAA;AAFR;;AAMA;EACI,eAAA;AAHJ;AACA,4lCAA4lC","sourcesContent":[".container {\n    padding: 30px;\n}\n\n.alert-icon {\n    font-size: 35px;\n}\n\nh1 {\n    font-size: 22px;\n    font-weight: 500;\n}\n\nh2 {\n    color: #949494;\n    font-size: 16px;\n    font-weight: 400;\n}\n\n.close {\n\n    min-height: auto;\n    \n\n    &::part(native) {\n        padding: 0;\n    }\n\n    ion-icon {\n        color: black;\n        font-size: 20px;\n    }\n}\n\n.delete-button > ion-icon {\n    font-size: 22px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
