// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  font-size: 14px;
  font-weight: 600;
}

ion-button {
  min-height: 63px;
}

.option-margin-bottom {
  margin-bottom: 8px;
}

.binding-options {
  padding: 5px;
}
.binding-options.coverColor {
  margin-bottom: 8px;
  margin-top: 8px;
}

.detailed-options {
  border: 2px solid rgba(0, 198, 255, 0.3);
  border-radius: 10px;
}
.detailed-options.detailed-option-background {
  background-color: rgba(0, 201, 255, 0.1);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm9wdGlvbi10dXJucGFnZS1idXR0b24uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0VBQ0EsZ0JBQUE7QUFDRjs7QUFHQTtFQUNFLGdCQUFBO0FBQUY7O0FBR0E7RUFDRSxrQkFBQTtBQUFGOztBQUdBO0VBQ0UsWUFBQTtBQUFGO0FBRUU7RUFDRSxrQkFBQTtFQUNBLGVBQUE7QUFBSjs7QUFJQTtFQUNFLHdDQUFBO0VBQ0EsbUJBQUE7QUFERjtBQUdFO0VBQ0Usd0NBQUE7QUFESiIsImZpbGUiOiJvcHRpb24tdHVybnBhZ2UtYnV0dG9uLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnRpdGxlIHtcbiAgZm9udC1zaXplOiAxNHB4O1xuICBmb250LXdlaWdodDogNjAwO1xuICAvLyBsaW5lLWhlaWdodDogMzVweDtcbn1cblxuaW9uLWJ1dHRvbiB7XG4gIG1pbi1oZWlnaHQ6IDYzcHg7XG59XG5cbi5vcHRpb24tbWFyZ2luLWJvdHRvbSB7XG4gIG1hcmdpbi1ib3R0b206IDhweDtcbn1cblxuLmJpbmRpbmctb3B0aW9ucyB7XG4gIHBhZGRpbmc6IDVweDtcblxuICAmLmNvdmVyQ29sb3Ige1xuICAgIG1hcmdpbi1ib3R0b206IDhweDtcbiAgICBtYXJnaW4tdG9wOiA4cHg7XG4gIH1cbn1cblxuLmRldGFpbGVkLW9wdGlvbnMge1xuICBib3JkZXI6IDJweCBzb2xpZCByZ2IoMCAxOTggMjU1IC8gMzAlKTtcbiAgYm9yZGVyLXJhZGl1czogMTBweDtcblxuICAmLmRldGFpbGVkLW9wdGlvbi1iYWNrZ3JvdW5kIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiByZ2IoMCAyMDEgMjU1IC8gMTAlKVxuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/home/option-turnpage-button/option-turnpage-button.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;AACF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,YAAA;AAAF;AAEE;EACE,kBAAA;EACA,eAAA;AAAJ;;AAIA;EACE,wCAAA;EACA,mBAAA;AADF;AAGE;EACE,wCAAA;AADJ;AACA,omCAAomC","sourcesContent":[".title {\n  font-size: 14px;\n  font-weight: 600;\n  // line-height: 35px;\n}\n\nion-button {\n  min-height: 63px;\n}\n\n.option-margin-bottom {\n  margin-bottom: 8px;\n}\n\n.binding-options {\n  padding: 5px;\n\n  &.coverColor {\n    margin-bottom: 8px;\n    margin-top: 8px;\n  }\n}\n\n.detailed-options {\n  border: 2px solid rgb(0 198 255 / 30%);\n  border-radius: 10px;\n\n  &.detailed-option-background {\n    background-color: rgb(0 201 255 / 10%)\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
