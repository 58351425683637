import { Injectable } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { Finishing, PrintColor, _PrintFile } from 'src/app/models/models';
import { newVendor, PrintProduct } from 'src/app/models/printProduct.model';
import { Product } from 'src/app/models/product.model';
import { AppService } from '../app.service';
import { ProductDbService } from '../database/product-db.service';
import { ProductService } from '../product.service';
import { default as slugify } from 'slugify';
import { UserService } from 'src/app/core/user.service';
import { filter, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { FileService } from '../file.service';
import { SlugService } from 'src/app/shared-lib';

@Injectable({
  providedIn:'any'
})
export class PrintProductBlService {
  private settingOptions = ['color', 'pageSize', 'paperWeight','twoSided', 'pagesPerSheet', 'autoRotate',  'pageOrientation', 'pageTurn', 'finishing', 'grouped', 'coverColor','ringColor','docColor'];

  private readonly _settingsChanged = new BehaviorSubject<Date>(new Date());
  readonly settingsChanged$ = this._settingsChanged.asObservable();  

  private readonly _printProduct = new BehaviorSubject<Product>(null);
  readonly printProduct$ = this._printProduct.asObservable();  

  constructor(
    private productDbService: ProductDbService,
    private productService: ProductService,
    public  appService: AppService,
    public  userService: UserService,
    private fileService: FileService,
    private slugService: SlugService

  ){
  }

  printProduct = ()=>this._printProduct.value;

  getPrintProduct(id){
    this.productDbService.get$(id).pipe(
      take(1),
      tap(product=>{
        // console.log("######Producto", product)
       
      })
    ).subscribe(
      printProduct => {
        if (!printProduct.createdAt){
          printProduct.id = null
        }
        printProduct.description = printProduct.description.trim().replace(/<br>/g, '\n')
        this._printProduct.next(printProduct)
      },
      err=>{console.log("ERRRR", err)},
      () => this._settingsChanged.next(new Date())
    );
  }

  setPrintProduct = (product) => product && this._printProduct.next(product);

  savedFile$ = this.printProduct$.pipe(
    filter(printProduct => !!printProduct),
    switchMap(printProduct=>{
      const fileId = printProduct.printingGroup.files[0].id
      const path = `products/${printProduct.id}/uploads`
      return this.fileService.getTemporaryFile(fileId, path)
    }),
    shareReplay(1)
  )



  newPrintProduct(){
    const printProduct = this.productService.newPrintProduct();
    printProduct.id = this.productDbService.createId();    
    printProduct.internal = false;
    printProduct.stock = 0;
    printProduct.soldQty = 0;

    this._printProduct.next(printProduct);
    this._settingsChanged.next(new Date());
  }

  async savePrintProduct(printProduct: PrintProduct, isNew:boolean): Promise<any>{
    printProduct.description = printProduct.description.trim().replace(/\n/g, '<br>')
    // printProduct.pictureUrl = printProduct.printingGroup.files[0].thumbFile.url;
    printProduct.title = printProduct.title.trim();

    //const newSlug = await this.slugService.genSlug(sugSlug, 'products');
    printProduct.slug = printProduct.slug ?? await this.slugService.genSlug(printProduct.title, 'products');
   // printProduct.slug = printProduct.slug ?? slugify(printProduct.title.toLowerCase());

    printProduct.vendor =  printProduct.vendor  ?? newVendor(this.userService.userG);
    printProduct.status = printProduct.status ?? 'active';
  
    delete printProduct.pictureUrl;

    // console.log("@@Save", printProduct);
    return isNew ? this.productDbService.set(printProduct) : this.productDbService.upsert(printProduct);
  }

  refreshPrice(printProduct: Product, viewFiles){
    this.productService.refreshPrintGroupPrice(printProduct, viewFiles)
  }
  
  getTotalUnitPrice(printProduct: Product){
    return printProduct.unitPrice.amt  + printProduct.extra.price
  }

  initSettings(setting){ 
    let viewSettings = this.appService.getPrintSettings();
    this.settingOptions.forEach(settingOption => {
      if (setting[settingOption] === undefined){
        setting[settingOption] = this.productService.getDefaultSettingOption(settingOption);       
      }
      const i = viewSettings[settingOption].options.findIndex(option => {
        return isEqual(option.value, setting[settingOption] );
      })
      viewSettings[settingOption].selected = i + 1;
    });
    return viewSettings;
  }


  setprintingGroupSettings(setting, option, settings){
    const _printProduct = this._printProduct.value;
    _printProduct.printingGroup.printSettings[setting] = settings[setting].options[option - 1].value;

    //JA temporal this._printProduct.next(_printProduct);
    this._settingsChanged.next(new Date());
  }  


  refresh(){
    this._settingsChanged.next(new Date());
  }  
}