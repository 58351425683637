import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Product } from 'src/app/models/product.model';
import { environment } from 'src/environments/environment';
import { ProductDbService } from '../database/product-db.service';

@Component({
  templateUrl: './promotion-dialog.component.html',
  styleUrls: ['./promotion-dialog.component.scss']
})
export class PromotionDialogComponent implements OnInit {
  slug =  environment.options.promotionProductSlug;
  product$: Observable<Product>;
  constructor(
    private productDbService: ProductDbService,
    private modalController: ModalController,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.product$ = this.productDbService.getBySlug$(this.slug);
  }

  onView(){
    this.closeDialog(true)
    this.router.navigate(['/papeleria/catalogo', this.slug]);
  }

  closeDialog(view=false){
    this.modalController.dismiss({
      'view': view
    });
  }


}
