// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

p {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRvb2x0aXAtaW5mby1mb2xkZXJzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZ0RBQUE7RUFDQSxnQkFBQTtFQUNBLGVBQUE7QUFDRjs7QUFFQTtFQUNFLGdEQUFBO0VBQ0EsZ0JBQUE7RUFDQSxlQUFBO0FBQ0YiLCJmaWxlIjoidG9vbHRpcC1pbmZvLWZvbGRlcnMuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJoMiB7XG4gIGZvbnQtZmFtaWx5OiBcIkJhcmxvdyBTZW1pIENvbmRlbnNlZFwiLCBzYW5zLXNlcmlmO1xuICBmb250LXdlaWdodDogNjAwO1xuICBmb250LXNpemU6IDE4cHg7XG59XG5cbnAge1xuICBmb250LWZhbWlseTogXCJCYXJsb3cgU2VtaSBDb25kZW5zZWRcIiwgc2Fucy1zZXJpZjtcbiAgZm9udC13ZWlnaHQ6IDQwMDtcbiAgZm9udC1zaXplOiAxNHB4O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/home-new/tooltip-info-components/tooltip-info-folders/tooltip-info-folders.component.scss"],"names":[],"mappings":"AAAA;EACE,gDAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,gDAAA;EACA,gBAAA;EACA,eAAA;AACF;AACA,4oBAA4oB","sourcesContent":["h2 {\n  font-family: \"Barlow Semi Condensed\", sans-serif;\n  font-weight: 600;\n  font-size: 18px;\n}\n\np {\n  font-family: \"Barlow Semi Condensed\", sans-serif;\n  font-weight: 400;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
