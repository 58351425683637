import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import ProductLabelComponent from './product-label.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule, 
    IonicModule,
    FlexLayoutModule
  ],
  exports: [ProductLabelComponent, ProductLabelComponent, ProductLabelComponent],
  declarations: [ProductLabelComponent, ProductLabelComponent, ProductLabelComponent],
})
export class ProductLabelModule { }
