import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ModalController } from "@ionic/angular";

@Component({
  selector: 'app-blank-sheets-modal',
  templateUrl: './blank-sheets-modal.component.html',
  styleUrls: ['./blank-sheets-modal.component.scss'],
})
export class BlankSheetsModalComponent implements OnInit {

  @Input() sheetsBefore = 0;
  @Input() sheetsAfter = 0;

  constructor(private modalController: ModalController) {
  }

  ngOnInit() {
  }

  cancel() {
    this.modalController.dismiss();
  }

  changeSheetQty(counterPosition, increment) {
    if (counterPosition === 1) {
      this.sheetsBefore = Math.min(999, Math.max(0, this.sheetsBefore + increment));
    } else {
      this.sheetsAfter = Math.min(999, Math.max(0, this.sheetsAfter + increment));
    }
  }

  limit(event, counterPosition) {
    const inputValue = event.target.valueAsNumber;
    if (!isNaN(inputValue) && inputValue > 999) {
      event.target.value = '999';
      counterPosition === 1 ? this.sheetsBefore = 999 : this.sheetsAfter = 999;
    }

    if (!isNaN(inputValue) && inputValue < 0) {
      event.target.value = '0';
      counterPosition === 1 ? this.sheetsBefore = 0 : this.sheetsAfter = 0;
    }
  }

  submit() {
    this.modalController.dismiss(({
      blankSheetsBefore: this.sheetsBefore ?? 0,
      blankSheetsAfter: this.sheetsAfter ?? 0
    }));
  }
}
