import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AddDocumentPopupComponent } from './add-document-popup.component';
import { PartialDocumentDetailModule } from '../partial-document-detail/partial-document-detail.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    PartialDocumentDetailModule,
    FlexLayoutModule

  ],
  declarations: [AddDocumentPopupComponent]
})
export class AddDocumentPopupModule { }
