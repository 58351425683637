import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DevelopmentGuard } from './guards/development.guard';
import { CanLeaveGuard } from './guards/can-leave.guard';
import { HomePage } from "./home/home.page";
import { HomeNewComponent } from "./home-new/home-new.component";


const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'folder/Inbox',
  //   pathMatch: 'full'
  // },
  {
    path: 'development-login',
    loadChildren: () => import('./development-login/development-login.module').then(m => m.DevelopmentLoginPageModule)
  },
  {
    path: '', canActivate: [DevelopmentGuard], children: [
      {
        path: 'folder',
        loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule)
      },
      {
        path: '',
        component: HomeNewComponent,
        loadChildren: () => import('./home-new/home-new.module').then(m => m.HomeNewModule),
        canDeactivate: [CanLeaveGuard]
      },
      { path: 'auth/login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
      { path: 'auth/signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule) },
      {
        path: 'auth/forgot-password',
        loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
      },
      {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountPageModule)
      },
      {
        path: 'order',
        loadChildren: () => import('./order/order.module').then(m => m.OrderPageModule)
      },
      {
        path: 'pedidos',
        loadChildren: () => import('./order/order.module').then(m => m.OrderPageModule)
      },
      {
        path: 'cart',
        loadChildren: () => import('./cart/cart.module').then(m => m.CartPageModule)
      },
      // {
      //   path: 'payment',
      //   loadChildren: () => import('./payment/payment.module').then( m => m.PaymentPageModule)
      // },
      // {
      //   path: 'products',
      //   // Contains ?type= ....
      //   loadChildren: () => import('./products/products.module').then( m => m.ProductsPageModule)
      // },
      {
        path: 'payment-result',
        loadChildren: () => import('./payment-result/payment-result.module').then(m => m.PaymentResultPageModule)
      },
      {
        path: 'test',
        loadChildren: () => import('./test/test.module').then(m => m.TestPageModule)
      },
      {
        path: 'wallet',
        loadChildren: () => import('./wallet/wallet.module').then(m => m.WalletPageModule)
      },
      {
        path: 'embajadores',
        loadChildren: () => import('./embajadores/embajadores.module').then(m => m.EmbajadoresPageModule)
      },
      {
        path: 'out-of-service',
        loadChildren: () => import('./out-of-service/out-of-service.module').then(m => m.OutOfServicePageModule)
      },
      {
        path: 'productos',
        redirectTo: 'papeleria/catalogo'
      },
      {
        path: 'libreria-creadores',
        loadChildren: () => import('./creators/creators-library/creators-library.module').then(m => m.CreatorsLibraryPageModule)
      },
      {
        path: 'biblioteca',
        loadChildren: () => import('./creators/creators-documents/creators-documents.module').then(m => m.CreatorsDocumentsPageModule)
      },
      {
        path: 'creator-page',
        loadChildren: () => import('./creators/creator-page/creator-page.module').then(m => m.CreatorPagePageModule)
      },
      {
        path: 'document-page',
        loadChildren: () => import('./creators/document-page/document-page.module').then(m => m.DocumentPagePageModule)
      },
      {
        path: 'document-edit',
        loadChildren: () => import('./creators/document-edit/document-edit.module').then(m => m.DocumentEditPageModule)
      },
      {
        path: 'tienda',
        redirectTo: 'papeleria'
      },
      {
        path: 'papeleria',
        loadChildren: () => import('./shop/shop.module').then(m => m.ShopPageModule)
      },
      {
        path: 'mantenimiento',
        loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule)
      },
      {
        path: 'print-simulator',
        loadChildren: () => import('./print-simulator/print-simulator.module').then(m => m.PrintSimulatorPageModule)
      },
      {
        path: '**',
        redirectTo: ''
      },
    ]
  },


];

// routes.unshift(  {path: '**', loadChildren: () => import('./out-of-service/out-of-service.module').then( m => m.OutOfServicePageModule)})

//TODO J.A. revisar bien el ruteo hay cambios en guard, relativeLinkResolution: 'legacy' y trataremos de no hacer un preloading de tododos lo smodulos
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
