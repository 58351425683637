import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addressOrder'
})
export class AddressOrderPipe implements PipeTransform {


  /**
   * Se Ordena el address
   * @param address
   * @returns un objeto con la direccion ordenada 
   */
  transform(address: any): {} {
    let addressInOrder = {
      // recipient: address.recipient ,
      addressLine1: address.addressLine1 ,
      addressLine2: address.addressLine2,
      city: address.city,
      postalCode: address.postalCode,
      province: address.province, 
    } 
    return addressInOrder;
  }
}
