import {
  Component,
  DoCheck, ElementRef, HostListener,
  Input,
  KeyValueDiffers,
  OnChanges,
  OnInit, Renderer2,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import { lastValueFrom } from "rxjs";
import { add } from 'lodash';

@Component({
  selector: 'app-notebook-preview',
  templateUrl: './notebook-preview.component.html',
  styleUrls: ['./notebook-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotebookPreviewComponent implements OnInit, DoCheck {
  @Input() optionsSelected!: any;
  differ;
  elements = {
    front: { type: 'svg', src: 'assets/images/product-preview/notebook/old/notebook-coverfront-old.svg' },
    inner: { type: 'img', src: 'assets/images/product-preview/notebook/old/notebook-background-basic-old.png' },
    back: { type: 'svg', src: 'assets/images/product-preview/notebook/old/notebook-coverback-old.svg' },
  }

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private differs: KeyValueDiffers,
              private el: ElementRef, private renderer: Renderer2) {
    this.differ = this.differs.find({}).create();
  }

  ngOnInit() {
    this.adjustContainerHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustContainerHeight();
  }

  adjustContainerHeight() {
    const container = this.el.nativeElement.querySelector('#notebook-preview-container');
    const front = this.el.nativeElement.querySelector('#front');
    if (container && front) {
      const frontImage = front.getElementsByTagName('svg')[0] as HTMLElement;
      const height = frontImage.clientHeight;
      container.style.height = `${height}px`;
    }
  }

  ngDoCheck() {
    const diff = this.differ.diff(this.optionsSelected);
    if (diff) {
      let changes = []; // Para elementos que han cambiado
      let added = []; // Para nuevos elementos

      // Detectar cambios en elementos existentes
      diff.forEachChangedItem((record) => {
        changes.push({
          key: record.key,
          value: record.currentValue
        });
      });

      // Detectar nuevos elementos añadidos
      diff.forEachAddedItem((record) => {
        added.push({
          key: record.key,
          value: record.currentValue
        });
      });

      if (changes.length > 0) {
        this.updatePreview(changes);
      }

      if (added.length > 0) {
        this.updatePreview(added.filter(ad => !!ad.value))
      }
    }
  }

  updatePreview(props) {
    props.forEach(prop => {
      if (!prop.value) {
        return
      }
      switch (prop.key) {
        case 'coverFront':
          const elementFront = this.el.nativeElement.querySelector('#front');
          if (elementFront) {
            const svg = elementFront.querySelector('svg');
            if (svg) {
              if (prop.value.id === 'transparent') {
                this.renderer.setStyle(svg, 'opacity', '10%');
                this.renderer.setStyle(svg, 'fill', 'black');
              } else {
                this.renderer.setStyle(svg, 'opacity', '85%');
                this.renderer.setStyle(svg, 'fill', prop.value.color);
              }
            }
          }
          break;
        case 'coverBack':
          const elementBack = this.el.nativeElement.querySelector('#back');
          if (elementBack) {
            const svg = elementBack.querySelector('svg');
            if (svg) {
              if (prop.value.id === 'transparent') {
                this.renderer.setStyle(svg, 'opacity', '10%');
                this.renderer.setStyle(svg, 'fill', 'black');
              } else {
                this.renderer.setStyle(svg, 'opacity', '85%');
                this.renderer.setStyle(svg, 'fill', prop.value.color);
              }
            }
          }
          break;
        case 'rings':
          const elementRings = this.el.nativeElement.querySelector('#rings');
          if (elementRings) {
            const svg = elementRings.querySelector('svg');
            if (svg) {
              if (prop.value.id === 'transparent') {
                this.renderer.setStyle(svg, 'opacity', '40%');
                this.renderer.setStyle(svg, 'fill', 'white');
              } else {
                this.renderer.setStyle(svg, 'opacity', '65%');
                this.renderer.setStyle(svg, 'fill', prop.value.color);
              }
            }
          }
          break;

        case 'paperColor':
          const elementInner = this.el.nativeElement.querySelector('#inner');
          if (elementInner) {
            const img = elementInner.querySelector('img');
            const svg = elementInner.querySelector('svg');
            if (svg && img) {
              if (prop.value.data.src) {
                img.src = prop.value.data.src;
              } else {
                img.src = 'assets/images/product-preview/notebook/old/notebook-background-basic-old.png';
              }
              if (prop.value.data.color) {
                this.renderer.setStyle(svg, 'opacity', '50%');
                this.renderer.setStyle(svg, 'fill', prop.value.data.color);
              } else {
                this.renderer.setStyle(svg, 'opacity', '0%');
              }
            }
          }
          break;

        case 'paperPattern':
          const elementInnerMotif = this.el.nativeElement.querySelector('#inner');
          if (elementInnerMotif) {
            let img = elementInnerMotif.querySelector('img');
            let svg = elementInnerMotif.querySelector('svg');
            if (svg && img) {
              if (prop.value.value === 'Liso') {
                img.src = 'assets/images/product-preview/notebook/old/notebook-background-basic-old.png';
              }
              if (prop.value.value === 'Rayitas') {
                img.src = 'assets/images/product-preview/notebook/old/notebook-background-lines-old.png';
              }
              if (prop.value.value === 'Puntitos') {
                img.src = 'assets/images/product-preview/notebook/old/notebook-background-points-old.png';
              }
            }
          }

          break;

      }
    })
  }

  public async getSvg(url: string) {
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/svg+xml');
    return await lastValueFrom(this.http.get(url, { headers, responseType: 'text' }))
  }
}
