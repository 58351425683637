import { cloneDeep } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, } from 'rxjs/operators';
import { ProductPageModel } from 'src/app/models/product-ui.model';
import { Product } from 'src/app/models/product.model';
import isCustom = Product.isCustom;

export const productSelected$ = (product$: Observable<any>, _qty: BehaviorSubject<number>, _variantSelected: BehaviorSubject<Product.Variant>) =>
  combineLatest([product$, _qty, _variantSelected]).pipe(
    map(([product, qty, variant]) => {
      let uiProduct: ProductPageModel = cloneDeep(product) as ProductPageModel;
      if (!uiProduct) return null;
      uiProduct.variantId = null;
      uiProduct.isCustom= isCustom(product);
      if (product.variantCount) {
        if (!variant) {
          uiProduct = { ...uiProduct, ...Product.cheapestVariant(product.variants) };
          uiProduct.allowSaleNoStock = false;
          uiProduct.stock = 0;
        } else {
          uiProduct = { ...uiProduct, ...variant, id: product.id, variantId: variant.id };
          uiProduct.variantId = variant.id
        }
      }
      //TODO falta acomodar las foto .
      uiProduct.pictureUrl = uiProduct.pictureUrl ?? product.pictureUrl
      uiProduct.price = Product.saledPrice(uiProduct);
      uiProduct.qty = qty;
      uiProduct.hasVariant = !!product.variantCount;
      uiProduct.canAdd = !Product.isSoldOut(uiProduct);
      uiProduct.isSoldOut = Product.isAllProductSoldOut(uiProduct);
      // console.log("uiProduct", uiProduct)
      return uiProduct;
    })
  )

export abstract class ProductHandle {
  _qty = new BehaviorSubject<number>(1);
  _variantSelected = new BehaviorSubject<Product.Variant>(null);
  variantSelected$ = this._variantSelected.asObservable();
  slug: string;

  product$: Observable<any>;//=> producto cogido de la BLService de BD
  productSelected$;

  constructor() {
  }
}
