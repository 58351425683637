import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import semver from 'semver';
import { environment } from 'src/environments/environment';
import { AppService } from '../shared/app.service';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  isInMaintenance = false
  isAlertOpen: boolean = false;

  constructor(
    private appService: AppService,
    private alertController: AlertController,
    private router: Router,
  ) {
    this.appService.generalSettings$.subscribe(general => {
      if (general.minVersion) {
        this.checkVersion(general.minVersion)
      }
      this.checkMaintenance(general.inMaintenance)
    })
  }

  async checkMaintenance(inMaintenance) {
    if (inMaintenance) {
      this.router.navigate(['mantenimiento']);
    } else {
      if (this.isInMaintenance && !inMaintenance) {
        this.router.navigate(['/']);
        setTimeout(() => window.location.reload(), 100);
      }
    }
    this.isInMaintenance = inMaintenance;

  }

  async checkVersion(minVersion) {
    const version = environment.version;
    if (semver.lt(version, minVersion) && !this.isAlertOpen) {
      await this.presentAlert();
      this.isAlertOpen = false;
      window.location.reload();
    }
  }

  async presentAlert() {
    this.isAlertOpen = true;
    const alert = await this.alertController.create({
      header: '¡Quieto ahí!',
      subHeader: 'Estás desactualizado',
      message: `Tenemos una nueva versión de Copyfly. Vamos a refrescar tu navegador para que puedas seguir comprando.<br><br>(Tranquil@, no perderás tu carrito)`,
      buttons: ['Vale']
    });
    await alert.present();

    return await alert.onDidDismiss();

  }

}
