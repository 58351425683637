import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/core/auth.service';
import { OrderStateService } from '../order-state.service';

@Component({
  selector: 'app-menu-popover',
  templateUrl: './menu-popover.component.html',
  styleUrls: ['./menu-popover.component.scss'],
})
export class MenuPopoverComponent implements OnInit {

  constructor(
    private popoverController: PopoverController,
    private orderStateService: OrderStateService,
    private authService: AuthService,
    public router: Router,

  ) { }

  ngOnInit() {}

  close() {
    this.popoverController.dismiss();
  }  

  doLogout() {
    this.authService.doLogout()
    .then(res => this.router.navigate(['']));
    this.orderStateService.resetOrder(); 
  }  
}
