import { UserModel } from "../core/user.model";
import { MediaFile } from "./media.model";
import { PageSize, PagesPerSheet, PaperWeight, Price, PrintColor } from "./models";
import { Product } from "./product.model";

export interface PrintProduct extends Product {
  internal?: boolean;  
}

export interface PrintProductDb extends Omit<PrintProduct, 'variants'> {
  variants: Array<PrintVariant>
}

export interface  PrintVariant {
  options: PrintOption;
  price: number;
}

export type PrintOption = [PrintColor, PageSize, PaperWeight, boolean, PagesPerSheet]

export const newVendor = (user: UserModel): Product.VendorRef =>{
  const {id, displayName, photoURL} = user;
  return {id, name: displayName, thumbnail: photoURL};
}

export namespace PrintProduct{

  export const  saledPrice = (printProduct: Product) =>{
    const extraPrice = printProduct.extra?.price ?? 0;
    const royalty = printProduct.royalty ?? 0;
    return {
      amt: printProduct.unitPrice.amt  + extraPrice + royalty,
      cur: printProduct.unitPrice.cur
    }
  }

  export const unitPrice= (printProduct: Product) =>{
    const extraPrice = printProduct.extra?.price ?? 0;
    return {
      amt: printProduct.unitPrice.amt  + extraPrice ,
      cur: printProduct.unitPrice.cur
    }
  }

  export type DocumentList = 
    Pick<Product, 'id' |'title' | 'vendor' | 'pictureUrl' | 'unitPrice' | 'extra' | 'slug' | 'soldQty' | 'status'>
    & {price: Price, thumbnail: string}

  export const newDocumentList = (printProduct: Product): DocumentList =>{
    const {id, title, vendor, pictureUrl, unitPrice, extra, slug, soldQty, status} = printProduct
    const price = saledPrice(printProduct);
    const thumbnail = pictureUrl ? MediaFile.addNameSuffix(pictureUrl,"m") : '';
    return {id, title, vendor, thumbnail, unitPrice, extra, slug, soldQty, price, status};
  }


}

