// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-img {
  width: 350px;
  height: 620px;
  max-width: 100%;
  max-height: 100%;
  background-image: url('orientacion.70e890a29bfea34c.png');
  background-repeat: no-repeat;
  background-size: 100%, auto;
  overflow: hidden !important;
}

ion-icon {
  --ionicon-stroke-width: 44px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImluZm8tcGFzYXItcGFnaW5hLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsWUFBQTtFQUNBLGFBQUE7RUFDQSxlQUFBO0VBQ0EsZ0JBQUE7RUFFQSx5REFBQTtFQUNBLDRCQUFBO0VBR0EsMkJBQUE7RUFFQSwyQkFBQTtBQUhGOztBQUtBO0VBRUUsNEJBQUE7QUFIRiIsImZpbGUiOiJpbmZvLXBhc2FyLXBhZ2luYS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5iYWNrLWltZ3tcbiAgd2lkdGg6MzUwcHg7XG4gIGhlaWdodDogNjIwcHg7XG4gIG1heC13aWR0aDogMTAwJTtcbiAgbWF4LWhlaWdodDogMTAwJTtcbiAgLy8gYmFja2dyb3VuZC1pbWFnZTogdXJsKFwiLi9hc3NldHMvaW1hZ2VzL29yaWVudGFjaW9uLnBuZ1wiKTtcbiAgYmFja2dyb3VuZC1pbWFnZTogdXJsKFwiLi4vLi4vLi4vYXNzZXRzL2ltYWdlcy9vcmllbnRhY2lvbi5wbmdcIik7XG4gIGJhY2tncm91bmQtcmVwZWF0OiBuby1yZXBlYXQ7XG4gIC8vIGJhY2tncm91bmQtc2l6ZTogY29udGFpbjtcbiAgLy8gYmFja2dyb3VuZC1zaXplOiAxMDAlIDEwMCU7XG4gIGJhY2tncm91bmQtc2l6ZTogMTAwJSwgYXV0bztcbiAgLy8gYmFja2dyb3VuZC1zaXplOiBpbmhlcml0O1xuICBvdmVyZmxvdzogaGlkZGVuICFpbXBvcnRhbnQ7XG59XG5pb24taWNvbiB7XG4gIC8vIGZvbnQtc2l6ZTogNjRweDtcbiAgLS1pb25pY29uLXN0cm9rZS13aWR0aDogNDRweDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/info-pasar-pagina/info-pasar-pagina.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;EAEA,yDAAA;EACA,4BAAA;EAGA,2BAAA;EAEA,2BAAA;AAHF;;AAKA;EAEE,4BAAA;AAHF;AACA,oiCAAoiC","sourcesContent":[".back-img{\n  width:350px;\n  height: 620px;\n  max-width: 100%;\n  max-height: 100%;\n  // background-image: url(\"./assets/images/orientacion.png\");\n  background-image: url(\"../../../assets/images/orientacion.png\");\n  background-repeat: no-repeat;\n  // background-size: contain;\n  // background-size: 100% 100%;\n  background-size: 100%, auto;\n  // background-size: inherit;\n  overflow: hidden !important;\n}\nion-icon {\n  // font-size: 64px;\n  --ionicon-stroke-width: 44px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
