import { Component, Input, OnInit } from '@angular/core';

export enum MessageTypes {
  Standard,
  Success,
  Error,
  Warning,
  Primary
}

@Component({
  selector: 'app-message',
  template: `
  <div class="message" [ngClass]="{
    'message--error': MessageTypes.Error === type,
    'message--success': MessageTypes.Success === type,
    'message--warning': MessageTypes.Warning === type,
    'message--standard': MessageTypes.Standard === type,
    'message--primary': MessageTypes.Primary === type
  }">
    <ion-icon name="information-circle-outline"></ion-icon>
    <ng-content></ng-content>
  </div>
  `,
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  @Input() type = MessageTypes.Standard;
  MessageTypes = MessageTypes;

  constructor() { }

  ngOnInit() { }
}
