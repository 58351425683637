import { SocialMedia } from '../models/common/common.model';
import { Fees, PostalAddress } from '../models/models';
import { Product } from "../models/product.model";

export class UserModel {
  id: string;
  displayName: string; //
  firstName: string; //
  lastName: string; //
  email: string;
  emailVerified: boolean;
  phoneNumber?: string;
  phoneNumberVerified: false;
  address?: string; //
  location: Location;
  locationUpdated: Date;
  photoURL: string;
  uid: string;
  regStatus: UserModel.RegStatus;
  provider: {
    // facebook.com:1278172
    // password:email
  };
  createdAt: Date;
  updatedAt: Date;
  monthlyPayment: boolean; // activación de remesa: true. sin remesa: false.
  isAdmin: boolean; // is administrative user
  shippingAddress?: PostalAddress;
  billingAddress?: PostalAddress;
  unrestrictedZones?: string[];

  acceptance: {
    privacy: boolean;
    tos: boolean;
    referrer: boolean;
    ads: boolean;
    printProtected: boolean;
  }
  //
  isAnonymous?: boolean;
  isCreator?: boolean; //New v4
  featured?: boolean; //new v4
  description?: string; //new v4
  slug?: string; //new v4
  fees?: Fees;

  socialMedia: SocialMedia[];
  role?: string;
  ambassadorProducts?: any[];
}

export class MetaReferrer { // sub-collection 'meta'
  accpetanceId?: string;
  userId: string;
  name: string;
  taxId?: string;
  address?: PostalAddress;
  bankAccount?: string;
}

export class Token { // sub-collection
  createdAt: Date;
  updatedAt: Date;
  device: any; // as reported by device
  lang: any; // as reported by device
  token: string; // push token
}

export namespace UserModel {
  export enum RegStatus {
    Completed,
    Location,
    Name
  }

  export class Reference {
    id: string;  // user id (reference to users collection)
    displayName: string;

    constructor(user: UserModel) {
      if (user) {
        const { id, displayName } = user;
        return { id, displayName };
      }
    }
  }
}

export class _User { // NEW 2
  id: string;  // user id (reference to users collection)
  firstName: string;
  lastName: string;

  constructor(user: UserModel) {
    if (user) {
      const { id, firstName, lastName } = user;
      return { id, firstName, lastName };
    }
  }
}

//New v4;
export const newCreatorRef = (user: UserModel) => {
  const { id, displayName, photoURL } = user;
  return { id, displayName, photoURL };
} 
