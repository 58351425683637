// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-container {
  color: black;
  overflow-y: scroll;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFkZC1kb2N1bWVudC1wb3B1cC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLFlBQUE7RUFDQSxrQkFBQTtBQUFGIiwiZmlsZSI6ImFkZC1kb2N1bWVudC1wb3B1cC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIlxuLnBvcHVwLWNvbnRhaW5lcntcbiAgY29sb3I6IGJsYWNrO1xuICBvdmVyZmxvdy15OiBzY3JvbGw7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/creators/add-document-popup/add-document-popup.component.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,kBAAA;AAAF;AACA,wXAAwX","sourcesContent":["\n.popup-container{\n  color: black;\n  overflow-y: scroll;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
