// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-icon {
  font-size: 18px;
}

h1 {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
}

.container {
  padding: 15px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRvb2x0aXAtZGVmYXVsdC1zdGFuZGFyZC1zaGlwcGluZy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGVBQUE7QUFDSjs7QUFFQTtFQUNJLGVBQUE7RUFDQSxnQkFBQTtFQUNBLGlCQUFBO0FBQ0o7O0FBRUE7RUFDSSxlQUFBO0VBQ0EsZ0JBQUE7QUFDSjs7QUFFQTtFQUNJLGFBQUE7QUFDSiIsImZpbGUiOiJ0b29sdGlwLWRlZmF1bHQtc3RhbmRhcmQtc2hpcHBpbmcuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJpb24taWNvbiB7XG4gICAgZm9udC1zaXplOiAxOHB4O1xufVxuXG5oMSB7XG4gICAgZm9udC1zaXplOiAxOHB4O1xuICAgIGZvbnQtd2VpZ2h0OiA1MDA7XG4gICAgbGluZS1oZWlnaHQ6IDIycHg7XG59XG5cbnAge1xuICAgIGZvbnQtc2l6ZTogMTRweDtcbiAgICBmb250LXdlaWdodDogNDAwO1xufVxuXG4uY29udGFpbmVyIHtcbiAgICBwYWRkaW5nOiAxNXB4O1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/cart/cart-preview/tooltip-default-standard-shipping/tooltip-default-standard-shipping.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;AACA,wtBAAwtB","sourcesContent":["ion-icon {\n    font-size: 18px;\n}\n\nh1 {\n    font-size: 18px;\n    font-weight: 500;\n    line-height: 22px;\n}\n\np {\n    font-size: 14px;\n    font-weight: 400;\n}\n\n.container {\n    padding: 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
