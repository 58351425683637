import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-check-autoturn',
  templateUrl: './check-autoturn.component.html',
  styleUrls: ['./check-autoturn.component.scss'],
})
export class CheckAutoturnComponent implements OnInit {
  @Input() settings;
  @Input() settingOption;
  @Input() disabled;
  @Output() optionSelect = new EventEmitter<any>(); 
    
  //Icono informativo
  infoIcon ={
    name:"information-circle-outline",
    color:"primary",
    tooltip: "Activa la rotación automática si tu documento contiene páginas en <b>horizontal y vertical</b>, y quieres que se respete la orientación original.<br><br><b>Sólo compatible con 1 página por cara.</b>",
    size: "small"
  }
  constructor() { }

  ngOnInit() {}

  selectOption(event, checkbox){
    setTimeout(()=>{
      const prev = this.settings[this.settingOption].selected;
      this.settings[this.settingOption].selected=checkbox.checked ? 1 : 2;
      this.optionSelect.emit({setting:this.settingOption, option:this.settings[this.settingOption].selected, prev});      
    }, 100)
    
  }  
}
