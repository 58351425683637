import { Injectable } from '@angular/core';
import { Meta } from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {

  constructor(private meta: Meta) {}

  updateMetaTags(tags: { name: string, content: string }[]): void {
    tags.forEach(tag => {
      this.meta.updateTag({ name: tag.name, content: tag.content });
    });
  }

  removeMetaTag(name: string): void {
    this.meta.removeTag(`name='${name}'`);
  }
}
