// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  font-size: 3em;
}

p {
  font-size: 1.3em;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1haW50ZW5hbmNlLnBhZ2Uuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGNBQUE7QUFDRjs7QUFDQTtFQUNFLGdCQUFBO0FBRUYiLCJmaWxlIjoibWFpbnRlbmFuY2UucGFnZS5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaDEge1xuICBmb250LXNpemU6IDNlbTtcbn1cbnAge1xuICBmb250LXNpemU6IDEuM2VtO1xufVxuXG4ubG9nbyB7XG4gIC8vIG1heC1oZWlnaHQ6IDIwMHB4O1xuICAvLyBtYXgtd2lkdGg6IDMwMHB4O1xuICAvLyB3aWR0aDogMzAwcHg7XG5cbiBcbiAgaW1ne1xuICAgIC8vIHdpZHRoOjEwMCU7XG4gICAgLy8gb2JqZWN0LWZpdDogY292ZXI7XG4gICAgLy8gb2JqZWN0LXBvc2l0aW9uOnRvcDtcbiAgfVxufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/maintenance/maintenance/maintenance.page.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AACA;EACE,gBAAA;AAEF;AACA,4kBAA4kB","sourcesContent":["h1 {\n  font-size: 3em;\n}\np {\n  font-size: 1.3em;\n}\n\n.logo {\n  // max-height: 200px;\n  // max-width: 300px;\n  // width: 300px;\n\n \n  img{\n    // width:100%;\n    // object-fit: cover;\n    // object-position:top;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
