// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close {
  font-size: 14px !important;
  --padding-end: 10px;
}

.img-product {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.description {
  font-size: 14px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb21vdGlvbi1kaWFsb2cuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSwwQkFBQTtFQUNBLG1CQUFBO0FBQ0Y7O0FBQ0E7RUFDRSxZQUFBO0VBQ0EsV0FBQTtFQUNBLG1CQUFBO0FBRUY7O0FBQUE7RUFDRSxlQUFBO0FBR0YiLCJmaWxlIjoicHJvbW90aW9uLWRpYWxvZy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbG9zZXtcbiAgZm9udC1zaXplOiAxNHB4ICFpbXBvcnRhbnQ7XG4gIC0tcGFkZGluZy1lbmQ6IDEwcHg7XG59XG4uaW1nLXByb2R1Y3R7XG4gIGhlaWdodDogMTAwJTtcbiAgd2lkdGg6IDEwMCU7XG4gIG9iamVjdC1maXQ6IGNvbnRhaW47XG59XG4uZGVzY3JpcHRpb257XG4gIGZvbnQtc2l6ZTogMTRweDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/promotion-dialog/promotion-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,mBAAA;AACF;;AACA;EACE,YAAA;EACA,WAAA;EACA,mBAAA;AAEF;;AAAA;EACE,eAAA;AAGF;AACA,omBAAomB","sourcesContent":[".close{\n  font-size: 14px !important;\n  --padding-end: 10px;\n}\n.img-product{\n  height: 100%;\n  width: 100%;\n  object-fit: contain;\n}\n.description{\n  font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
