import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { default as slugify } from 'slugify';


@Injectable({
  providedIn: 'root'
})
export class SlugService {

  constructor(
    private db: AngularFirestore,
  ) { }

  slugExist(slug, collection): Promise<boolean>{
    return this.db.collection<any>(collection, ref=>ref
      .where('slug','==', slug)
      .limit(1))
      .get()
      .toPromise()
      .then(snap=>!!(snap.docs && snap.docs.length))
  }

  async genSlug(text, collection): Promise<string>{
    const base = slugify(text).toLowerCase();
    let intents = 0, slugExist = false, slug = base;
    do {
      const suffix = intents ? '-' + intents : '';
      slug = base + suffix;
      slugExist = await this.slugExist(slug , collection)
    } while(slugExist && intents++ < 20);
    return slug;
  }
}

// .valueChanges({ idField: 'id' })
// .pipe(map(arr => arr[0]))
