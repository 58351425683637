import { UserModel } from 'src/app/core/user.model';
import { AppService } from './app.service';
import { _User } from './../core/user.model';
import { UserService } from 'src/app/core/user.service';
import { serverTimestamp } from '@angular/fire/firestore';

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import { Acceptance } from '../models/acceptance.model';
import { EncryptionService } from "./encryption.service";

/**
 * Servicio de Base de datos para el manejo de Aceptacion de terminos
 *
 * Actualmente se maneja con sufijo DB
 */
@Injectable({
  providedIn: 'root'
})
export class AcceptanceService {
  private collection: AngularFirestoreCollection<Acceptance>;

  constructor(
    private db: AngularFirestore,
    private userService: UserService,
    private appService: AppService,
    private encryptS: EncryptionService
  ) {
    this.collection = this.db.collection<Acceptance>('acceptances');
  }

  upsert(_data): Promise<any> {
    const { id, ...data } = _data;
    if (!id) {
      data.createdAt = serverTimestamp();
    }
    data.updatedAt = serverTimestamp();
    return (id)
      ? this.collection.doc(id).update(data)
      : this.collection.add(data);
  }

  async insert(acceptanceBase: Acceptance.Base, _user?): Promise<any> {
    const userTemp = _user ?? this.userService.userG;
    const user = userTemp?.id ? new UserModel.Reference(userTemp) : null;


    // const platform = await Device.getInfo();
    //FIXME J.A. colocar la plataform
    const platform = null;
    const ipInfo = await this.appService.getIpInfo();
    const data = {
      ...acceptanceBase,
      user, platform, ipInfo,
      createdAt: serverTimestamp()
    } as Acceptance;

    return this.collection.add(data)
  }

  async userAcceptance(user) {
    const acceptance: Acceptance.Base = {
      privacy: true,
      tos: true,
      referrer: null,
      ads: null,
      printProtected: null,
      ref: {
        id: user.id,
        col: "users"
      }
    }
    const resp = await this.insert(acceptance, user);
    return resp.id
  }

  getPrintAllowed() {
    try {
      const printAllowed = this.encryptS.decrypt(localStorage.getItem('printAllowed'));
      return printAllowed === 'true' ? true : printAllowed === 'false' ? false : null;
    } catch (e) {
      localStorage.removeItem('printAllowed');
      return null;
    }
  }

}
