import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appPreventClickOnCondition]'
})
export class PreventClickOnConditionDirective {

  @Input() appPreventClickOnCondition: () => boolean;

  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent): void {
    // Si la condición bloqueante se cumple, detenemos la propagación del evento y evitamos la acción por defecto
    if (this.appPreventClickOnCondition && !this.appPreventClickOnCondition()) {
      event.stopPropagation();
      event.preventDefault();
    } else {
    }
  }

}
