// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.align {
  flex-flow: nowrap;
}

.info {
  font-size: 13px;
}

.caption {
  font-size: 13px;
  color: gray;
  margin-left: 32px;
}

.check {
  min-width: 26px;
}

ion-icon {
  pointer-events: none;
}

.tooltip {
  line-height: 0 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoZWNrLWF1dG90dXJuLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsaUJBQUE7QUFDRjs7QUFHQTtFQUNFLGVBQUE7QUFBRjs7QUFHQTtFQUNFLGVBQUE7RUFDQSxXQUFBO0VBQ0EsaUJBQUE7QUFBRjs7QUFHQTtFQUNFLGVBQUE7QUFBRjs7QUFHQTtFQUNFLG9CQUFBO0FBQUY7O0FBR0E7RUFDRSx5QkFBQTtBQUFGIiwiZmlsZSI6ImNoZWNrLWF1dG90dXJuLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmFsaWduIHtcbiAgZmxleC1mbG93OiBub3dyYXA7XG59XG5cblxuLmluZm8ge1xuICBmb250LXNpemU6IDEzcHg7XG59XG5cbi5jYXB0aW9uIHtcbiAgZm9udC1zaXplOiAxM3B4O1xuICBjb2xvcjogZ3JheTtcbiAgbWFyZ2luLWxlZnQ6IDMycHg7XG59XG5cbi5jaGVjayB7XG4gIG1pbi13aWR0aDogMjZweDtcbn1cblxuaW9uLWljb24ge1xuICBwb2ludGVyLWV2ZW50czogbm9uZTtcbn1cblxuLnRvb2x0aXAge1xuICBsaW5lLWhlaWdodDogMCAhaW1wb3J0YW50O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/home/check-autoturn/check-autoturn.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,eAAA;EACA,WAAA;EACA,iBAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,oBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;AACA,gyBAAgyB","sourcesContent":[".align {\n  flex-flow: nowrap;\n}\n\n\n.info {\n  font-size: 13px;\n}\n\n.caption {\n  font-size: 13px;\n  color: gray;\n  margin-left: 32px;\n}\n\n.check {\n  min-width: 26px;\n}\n\nion-icon {\n  pointer-events: none;\n}\n\n.tooltip {\n  line-height: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
