// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#notebook-preview-container {
  width: 100%;
  position: relative;
}
#notebook-preview-container img, #notebook-preview-container svg {
  position: absolute;
}
#notebook-preview-container #front svg {
  fill: black;
  opacity: 10%;
}
#notebook-preview-container #back svg {
  fill: black;
  opacity: 85%;
}
#notebook-preview-container #inner svg {
  opacity: 0%;
}
#notebook-preview-container #rings svg {
  opacity: 40%;
  fill: white;
}

.cls-1 {
  background-color: blue;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5vdGVib29rLXByZXZpZXcuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxXQUFBO0VBQ0Esa0JBQUE7QUFDRjtBQUNFO0VBQ0Usa0JBQUE7QUFDSjtBQUdJO0VBQ0UsV0FBQTtFQUNBLFlBQUE7QUFETjtBQU1JO0VBQ0UsV0FBQTtFQUNBLFlBQUE7QUFKTjtBQVNJO0VBQ0UsV0FBQTtBQVBOO0FBWUk7RUFDRSxZQUFBO0VBQ0EsV0FBQTtBQVZOOztBQWdCQTtFQUNFLHNCQUFBO0FBYkYiLCJmaWxlIjoibm90ZWJvb2stcHJldmlldy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIiNub3RlYm9vay1wcmV2aWV3LWNvbnRhaW5lciB7XG4gIHdpZHRoOiAxMDAlO1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG5cbiAgaW1nLCBzdmcge1xuICAgIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgfVxuXG4gICNmcm9udCB7XG4gICAgc3ZnIHtcbiAgICAgIGZpbGw6IGJsYWNrO1xuICAgICAgb3BhY2l0eTogMTAlO1xuICAgIH1cbiAgfVxuXG4gICNiYWNrIHtcbiAgICBzdmcge1xuICAgICAgZmlsbDogYmxhY2s7XG4gICAgICBvcGFjaXR5OiA4NSU7XG4gICAgfVxuICB9XG5cbiAgI2lubmVyIHtcbiAgICBzdmcge1xuICAgICAgb3BhY2l0eTogMCU7XG4gICAgfVxuICB9XG5cbiAgI3JpbmdzIHtcbiAgICBzdmcge1xuICAgICAgb3BhY2l0eTogNDAlO1xuICAgICAgZmlsbDogd2hpdGVcbiAgICB9XG4gIH1cblxufVxuXG4uY2xzLTEge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiBibHVlO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shop/productos/product-custom-elements/previews/notebook-preview/notebook-preview.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAGI;EACE,WAAA;EACA,YAAA;AADN;AAMI;EACE,WAAA;EACA,YAAA;AAJN;AASI;EACE,WAAA;AAPN;AAYI;EACE,YAAA;EACA,WAAA;AAVN;;AAgBA;EACE,sBAAA;AAbF;AACA,wkCAAwkC","sourcesContent":["#notebook-preview-container {\n  width: 100%;\n  position: relative;\n\n  img, svg {\n    position: absolute;\n  }\n\n  #front {\n    svg {\n      fill: black;\n      opacity: 10%;\n    }\n  }\n\n  #back {\n    svg {\n      fill: black;\n      opacity: 85%;\n    }\n  }\n\n  #inner {\n    svg {\n      opacity: 0%;\n    }\n  }\n\n  #rings {\n    svg {\n      opacity: 40%;\n      fill: white\n    }\n  }\n\n}\n\n.cls-1 {\n  background-color: blue;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
