// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-auth-options ion-button {
  --background: white;
  --background-hover: white;
  --background-activated: white;
  --border-radius: 100px;
  margin: 0;
}
.social-auth-options ion-button::part(native) {
  border: 1px solid #CBCBCB;
  padding: 14px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.0509803922);
}
.social-auth-options ion-icon {
  font-size: 24px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNvY2lhbC1hdXRoLW9wdGlvbnMuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUk7RUFDRSxtQkFBQTtFQUNBLHlCQUFBO0VBQ0EsNkJBQUE7RUFDQSxzQkFBQTtFQUNBLFNBQUE7QUFETjtBQUdNO0VBQ0UseUJBQUE7RUFDQSxhQUFBO0VBQ0EsMERBQUE7QUFEUjtBQU1JO0VBQ0UsZUFBQTtBQUpOIiwiZmlsZSI6InNvY2lhbC1hdXRoLW9wdGlvbnMuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuc29jaWFsLWF1dGgtb3B0aW9ucyB7XG5cbiAgICBpb24tYnV0dG9uIHtcbiAgICAgIC0tYmFja2dyb3VuZDogd2hpdGU7XG4gICAgICAtLWJhY2tncm91bmQtaG92ZXI6IHdoaXRlO1xuICAgICAgLS1iYWNrZ3JvdW5kLWFjdGl2YXRlZDogd2hpdGU7XG4gICAgICAtLWJvcmRlci1yYWRpdXM6IDEwMHB4O1xuICAgICAgbWFyZ2luOiAwO1xuXG4gICAgICAmOjpwYXJ0KG5hdGl2ZSkge1xuICAgICAgICBib3JkZXI6IDFweCBzb2xpZCAjQ0JDQkNCO1xuICAgICAgICBwYWRkaW5nOiAxNHB4O1xuICAgICAgICBib3gtc2hhZG93OiAwcHggMXB4IDJweCAwcHggIzEwMTgyODBEO1xuICAgICAgfVxuXG4gICAgfVxuXG4gICAgaW9uLWljb24ge1xuICAgICAgZm9udC1zaXplOiAyNHB4O1xuICAgIH1cbiAgfSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/social-auth-options/social-auth-options.component.scss"],"names":[],"mappings":"AAEI;EACE,mBAAA;EACA,yBAAA;EACA,6BAAA;EACA,sBAAA;EACA,SAAA;AADN;AAGM;EACE,yBAAA;EACA,aAAA;EACA,0DAAA;AADR;AAMI;EACE,eAAA;AAJN;AACA,47BAA47B","sourcesContent":[".social-auth-options {\n\n    ion-button {\n      --background: white;\n      --background-hover: white;\n      --background-activated: white;\n      --border-radius: 100px;\n      margin: 0;\n\n      &::part(native) {\n        border: 1px solid #CBCBCB;\n        padding: 14px;\n        box-shadow: 0px 1px 2px 0px #1018280D;\n      }\n\n    }\n\n    ion-icon {\n      font-size: 24px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
