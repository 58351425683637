// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card{
  margin:0 !important;
  padding: 20px;
}

ion-card-header {
  text-align: center;
}

@media (max-width: 576px) {
  ion-card{
    padding: 5px;
  }
}

ion-button{
  font-weight: 600;
}

ion-card-title {
  font-size: 24px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFjY2VwdGFuY2UtbW9kYWwuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLG1CQUFtQjtFQUNuQixhQUFhO0FBQ2Y7O0FBRUE7RUFDRSxrQkFBa0I7QUFDcEI7O0FBRUE7RUFDRTtJQUNFLFlBQVk7RUFDZDtBQUNGOztBQUVBO0VBQ0UsZ0JBQWdCO0FBQ2xCOztBQUVBO0VBQ0UsZUFBZTtBQUNqQiIsImZpbGUiOiJhY2NlcHRhbmNlLW1vZGFsLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJpb24tY2FyZHtcbiAgbWFyZ2luOjAgIWltcG9ydGFudDtcbiAgcGFkZGluZzogMjBweDtcbn1cblxuaW9uLWNhcmQtaGVhZGVyIHtcbiAgdGV4dC1hbGlnbjogY2VudGVyO1xufVxuXG5AbWVkaWEgKG1heC13aWR0aDogNTc2cHgpIHtcbiAgaW9uLWNhcmR7XG4gICAgcGFkZGluZzogNXB4O1xuICB9XG59XG5cbmlvbi1idXR0b257XG4gIGZvbnQtd2VpZ2h0OiA2MDA7XG59XG5cbmlvbi1jYXJkLXRpdGxlIHtcbiAgZm9udC1zaXplOiAyNHB4O1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/acceptance-modal/acceptance-modal.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;AACA,4vBAA4vB","sourcesContent":["ion-card{\n  margin:0 !important;\n  padding: 20px;\n}\n\nion-card-header {\n  text-align: center;\n}\n\n@media (max-width: 576px) {\n  ion-card{\n    padding: 5px;\n  }\n}\n\nion-button{\n  font-weight: 600;\n}\n\nion-card-title {\n  font-size: 24px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
