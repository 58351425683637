import { Component, Input, OnInit } from '@angular/core';
import { CartService } from "../../shared/cart.service";
import { MenuController } from "@ionic/angular";

@Component({
  selector: 'app-mobile-toolbar',
  templateUrl: './mobile-toolbar.component.html',
  styleUrls: ['./mobile-toolbar.component.scss'],
})
export class MobileToolbarComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() title = '';
  isLoading = false;

  constructor(public cartService: CartService, private menuCtrl: MenuController) {
  }

  ngOnInit() {
  }

  openCartPreview() {
    if (this.cartService.currentStep < 1) {
      this.menuCtrl.open('cart-preview');
    }
  }

  onMenu() {
    this.menuCtrl.open();
  }

}
