import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from "@ionic/angular";

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {

  @Input() text = '';
  @Input() subtext = '';
  @Input() headerIconSrc = '';
  @Input() deleting = false;

  constructor(private modalController: ModalController) {
  }

  ngOnInit() {
  }

  cancel() {
    this.modalController.dismiss();
  }

  submit() {
    this.modalController.dismiss(({
      remove: true,
    }));
  }
}
