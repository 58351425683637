import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ShoppingCart } from 'src/app/models/cart.model';
import { Product } from 'src/app/models/product.model';
import { CartService } from 'src/app/shared/cart.service';
import { ProductService } from 'src/app/shared/product.service';

@Component({
  selector: 'app-item-vendor-printgroup',
  templateUrl: './item-vendor-printgroup.component.html',
  styleUrls: ['./item-vendor-printgroup.component.scss'],
})
export class ItemVendorPrintgroupComponent implements OnInit {
  @Input() item: ShoppingCart.OrderItem
  constructor(
    public productService: ProductService,
    private cartService: CartService,
  ) { }

  ngOnInit() {
  }

  onDelete(item){
    this.cartService.deleteProductCart(item);
  }

  onQtyChange(event){
    const product = this.item.product as Product;
    const qty = event.qty - this.item.qty     
    this.cartService.updateProductCart(product,  qty)
  }

}
