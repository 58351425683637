import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './maintenance.page.html',
  styleUrls: ['./maintenance.page.scss']
})
export class MaintenancePage implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
