import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-shop-card',
  templateUrl: './shop-card.component.html',
  styleUrls: ['./shop-card.component.scss'],
})
export class ShopCardComponent implements OnInit {
  @Input() card;
  @Output() addCart = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {}

  onCart(product) {
    this.addCart.emit(product)
  }

}
