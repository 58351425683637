// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  padding: 30px;
}

h3, h4, p {
  text-align: center;
}

.text-center {
  text-align: center;
}

ion-button::part(native) {
  padding-inline: 50px;
}

.title1 {
  font-size: 32px;
}

p.title2 {
  font-size: 20px;
}

ion-item ion-label {
  white-space: unset !important;
  overflow: visible !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByaW50LXBlcm1pc3Npb24tcG9saWN5LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtBQUNGOztBQUdBO0VBQ0Usa0JBQUE7QUFBRjs7QUFHQTtFQUNFLGtCQUFBO0FBQUY7O0FBSUU7RUFDRSxvQkFBQTtBQURKOztBQUtBO0VBQ0UsZUFBQTtBQUZGOztBQU1FO0VBQ0UsZUFBQTtBQUhKOztBQVNFO0VBQ0UsNkJBQUE7RUFDQSw0QkFBQTtBQU5KIiwiZmlsZSI6InByaW50LXBlcm1pc3Npb24tcG9saWN5LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNvbnRlbnQge1xuICBwYWRkaW5nOiAzMHB4O1xufVxuXG5cbmgzLCBoNCwgcCB7XG4gIHRleHQtYWxpZ246IGNlbnRlcjtcbn1cblxuLnRleHQtY2VudGVyIHtcbiAgdGV4dC1hbGlnbjogY2VudGVyO1xufVxuXG5pb24tYnV0dG9uIHtcbiAgJjo6cGFydChuYXRpdmUpIHtcbiAgICBwYWRkaW5nLWlubGluZTogNTBweDtcbiAgfVxufVxuXG4udGl0bGUxIHtcbiAgZm9udC1zaXplOiAzMnB4O1xufVxuXG5wIHtcbiAgJi50aXRsZTIge1xuICAgIGZvbnQtc2l6ZTogMjBweDtcbiAgfVxufVxuXG5cbmlvbi1pdGVtIHtcbiAgaW9uLWxhYmVsIHtcbiAgICB3aGl0ZS1zcGFjZTogdW5zZXQgIWltcG9ydGFudDtcbiAgICBvdmVyZmxvdzogdmlzaWJsZSAhaW1wb3J0YW50O1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/print-permission-policy/print-permission-policy.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAIE;EACE,oBAAA;AADJ;;AAKA;EACE,eAAA;AAFF;;AAME;EACE,eAAA;AAHJ;;AASE;EACE,6BAAA;EACA,4BAAA;AANJ;AACA,w+BAAw+B","sourcesContent":[".content {\n  padding: 30px;\n}\n\n\nh3, h4, p {\n  text-align: center;\n}\n\n.text-center {\n  text-align: center;\n}\n\nion-button {\n  &::part(native) {\n    padding-inline: 50px;\n  }\n}\n\n.title1 {\n  font-size: 32px;\n}\n\np {\n  &.title2 {\n    font-size: 20px;\n  }\n}\n\n\nion-item {\n  ion-label {\n    white-space: unset !important;\n    overflow: visible !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
