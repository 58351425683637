// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h4 {
  font-size: 16px;
  color: #949494;
}

p {
  font-size: 22px;
  border-radius: 20px;
  padding: 10px;
  background-color: #F6F6F6;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRvb2x0aXAtaW5mby1kZXNwYWdpbmF0aW9uLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZUFBQTtFQUNBLGNBQUE7QUFDRjs7QUFFQTtFQUNFLGVBQUE7RUFDQSxtQkFBQTtFQUNBLGFBQUE7RUFDQSx5QkFBQTtBQUNGIiwiZmlsZSI6InRvb2x0aXAtaW5mby1kZXNwYWdpbmF0aW9uLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaDQge1xuICBmb250LXNpemU6IDE2cHg7XG4gIGNvbG9yOiAjOTQ5NDk0XG59XG5cbnAge1xuICBmb250LXNpemU6IDIycHg7XG4gIGJvcmRlci1yYWRpdXM6IDIwcHg7XG4gIHBhZGRpbmc6IDEwcHg7XG4gIGJhY2tncm91bmQtY29sb3I6ICNGNkY2RjY7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/cover-settings/tooltip-info-despagination/tooltip-info-despagination.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;AACF;AACA,wkBAAwkB","sourcesContent":["h4 {\n  font-size: 16px;\n  color: #949494\n}\n\np {\n  font-size: 22px;\n  border-radius: 20px;\n  padding: 10px;\n  background-color: #F6F6F6;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
