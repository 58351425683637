import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { UploadTask } from '@angular/fire/compat/storage/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileDbService } from './file-db.service';

export interface UploadState {
  state: "running" | "success" | "canceled" | "error" | "paused" | "inactive";
  progress: number;
}

@Injectable(
)
export class FileUploadService {
  file: any;
  // file: FileModel;
  _uploadState = new BehaviorSubject<UploadState>({state: "inactive", progress: 0})
  uploadState$ = this._uploadState.asObservable();
  //  new Observable<UploadState>();
  task: AngularFireUploadTask;
  hasError=false;
  constructor(
    private fileDbService: FileDbService,
    private storage: AngularFireStorage    
  ) { 
    // console.log("Pobando 1")
  }

  init(){
    // console.log("Subiendo archivo");
  }

  /**
   * @param file2Upload the : File to be uploaded
   * @param basePath ex:'users/pepe' used to store the file in DB
   * @param collectionPath // ex:'users/pepe/photo'. where photo is a collection
   * @param _fileId optional to keep fixed the id
   */
  uploadFile(file2Upload, basePath, collectionPath, _fileId?){
    const fileId = _fileId??this.fileDbService.createId();
    const path = `${basePath}/${fileId}/${file2Upload.name}`;
    const ref = this.storage.ref(path);
    this.task = ref.put(file2Upload);
    this.task.snapshotChanges()
      .pipe(map(s => {
        // console.log("@@@@@ssss:", s);
        return {
          state: s.state,
          progress: (s.bytesTransferred / s.totalBytes) * 100
        }     
      })
    ).subscribe(this._uploadState)

    //se crea el archivo base
    let _file = this.newFile({
      path,
      name: file2Upload.name,
      size: file2Upload.size,
      version: 0,
      contentType: file2Upload.type,
      // ...this.reference
    });
    this.file=_file;

    return this.task
    .then(res => res.ref.getDownloadURL())
    .then(url => {
      _file.url = url;
      this.file=_file;
      // console.log("tenemos url",this.file);
      return this.fileDbService.create( fileId, _file, collectionPath);
    })
    .then(() => {
      // console.log("file uploaded");
    })
    .catch(err=>{
      this.hasError = true;
      console.error("Upload File Error", err);
    })
  }

  newFile(base){
    const file: any = {...base};
    // const file: FileModel = {...base};
    file.payload = {};
    // file.iconUrl = FileUtils.icon(file.contentType);
    return file;
  }

  cancelUpload(){
    const currentState = this._uploadState.value;
    this._uploadState.next({...currentState,  state: "canceled"})
    this.task.cancel();
    // this.deleteFile.emit(this.file2Upload)
  }


  /*
  * @param file selected file 
  * @returns The image in string
  */
  async loadImage(file:File): Promise<any>{
    const reader = new FileReader();
    // console.log(file);
    reader.readAsDataURL(file);
    return new Promise<any>((resolve, reject)=> {
      reader.onload = (event)=> resolve(event.target.result);
      reader.onerror = () =>    reject("Error Loading Image");
    })
 }

 getFile(event):File {return event?.target?.files[0];}
}
