// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section_title {
  font-weight: 400 !important;
}
.section_title.primary {
  color: var(--ion-color-primary);
}
.section_title.secondary {
  color: var(--ion-color-secondary);
}
.section_title.tertiary {
  color: var(--ion-color-tertiary);
}

.section_subtitle {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: #949494;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRvb2x0aXAtaW5mby1wYWdlLXNpemUuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSwyQkFBQTtBQUNGO0FBQ0U7RUFDRSwrQkFBQTtBQUNKO0FBRUU7RUFDRSxpQ0FBQTtBQUFKO0FBR0U7RUFDRSxnQ0FBQTtBQURKOztBQUtBO0VBQ0UsZ0RBQUE7RUFDQSxlQUFBO0VBQ0EsaUJBQUE7RUFDQSxnQkFBQTtFQUNBLGNBQUE7QUFGRiIsImZpbGUiOiJ0b29sdGlwLWluZm8tcGFnZS1zaXplLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnNlY3Rpb25fdGl0bGUge1xuICBmb250LXdlaWdodDogNDAwICFpbXBvcnRhbnQ7XG5cbiAgJi5wcmltYXJ5IHtcbiAgICBjb2xvcjogdmFyKC0taW9uLWNvbG9yLXByaW1hcnkpXG4gIH1cblxuICAmLnNlY29uZGFyeSB7XG4gICAgY29sb3I6IHZhcigtLWlvbi1jb2xvci1zZWNvbmRhcnkpXG4gIH1cblxuICAmLnRlcnRpYXJ5IHtcbiAgICBjb2xvcjogdmFyKC0taW9uLWNvbG9yLXRlcnRpYXJ5KVxuICB9XG59XG5cbi5zZWN0aW9uX3N1YnRpdGxlIHtcbiAgZm9udC1mYW1pbHk6IFwiQmFybG93IFNlbWkgQ29uZGVuc2VkXCIsIHNhbnMtc2VyaWY7XG4gIGZvbnQtc2l6ZTogMTRweDtcbiAgbGluZS1oZWlnaHQ6IDE0cHg7XG4gIGZvbnQtd2VpZ2h0OiA0MDA7XG4gIGNvbG9yOiAjOTQ5NDk0O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/home-new/tooltip-info-components/tooltip-info-page-size/tooltip-info-page-size.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF;AACE;EACE,+BAAA;AACJ;AAEE;EACE,iCAAA;AAAJ;AAGE;EACE,gCAAA;AADJ;;AAKA;EACE,gDAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAFF;AACA,g+BAAg+B","sourcesContent":[".section_title {\n  font-weight: 400 !important;\n\n  &.primary {\n    color: var(--ion-color-primary)\n  }\n\n  &.secondary {\n    color: var(--ion-color-secondary)\n  }\n\n  &.tertiary {\n    color: var(--ion-color-tertiary)\n  }\n}\n\n.section_subtitle {\n  font-family: \"Barlow Semi Condensed\", sans-serif;\n  font-size: 14px;\n  line-height: 14px;\n  font-weight: 400;\n  color: #949494;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
