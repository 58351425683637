import { MediaFile } from "./media.model";
import { Price } from "./models";
import { Product, ProductSubType } from "./product.model";
import Variant = Product.Variant;

export interface ProductList extends Product.Base {
  highestDiscount?: any;
  variants?: Variant[];
  price: Price;
  isSoldOut: boolean;
  slug: string;
  hasVariant: boolean;
  thumbnail: string;
  thumbnail2: string;
  exclusive?: boolean;
  featured?: boolean;
  presale?: boolean;
  title: string;
  isCustom: boolean,
  cheapestVariant?: Variant;
}

export const newProductList = (product: Product): ProductList => {
  const productBase = Product.newProductBase(product);
  return {
    ...productBase,
    price: Product.saledPrice(product),
    isSoldOut: Product.isAllProductSoldOut(product),
    isCustom: Product.isCustom(product),
    slug: product.slug,
    hasVariant: !!product.variantCount,
    thumbnail: product.pictureUrl ? MediaFile.addNameSuffix(product.pictureUrl, "m") : '',
    thumbnail2: product.pictureSecondaryUrl ? MediaFile.addNameSuffix(product.pictureSecondaryUrl, "m") : '',
    exclusive: product.exclusive,
    featured: product.featured,
    presale: product.presale,
    title: product.title
  }
}

export interface ProductPageModel extends Product {
  variantId: string;
  price: Price;
  isSoldOut: boolean;
  hasVariant: boolean;
  qty: number;
  canAdd: boolean;
  isCustom: boolean;
}


export interface PrintProductPageModel extends ProductPageModel {
  unitPrice: Price;
  total: number;
}
