export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function uidI() {
  return 'ixxxxxxx-yxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const ifMoment2Date = property => typeof property.toDate === 'function' ? property.toDate() : property;
export { ifMoment2Date }

const toDate = (property: any) => property && isFunction(property.toDate) ? property.toDate() : property;
export { toDate }

const isFunction = (fn: any) => typeof fn === "function";
export { isFunction }

export function enumToArray(enumme: { [x: string]: any; }) {
  return Object.keys(enumme)
    .map(key => enumme[key]);
}

export const round2 = (num) => Math.round((num + Number.EPSILON).toFixed(2) * 100) / 100;

export const arrToObject = (arr: Array<any>) => arr.reduce((o, val, i) => (o[i] = val, o), {});

export const objectToArr = (obj: any) => Object.keys(obj).map(k => obj[k]);
