import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalController } from "@ionic/angular";

@Component({
  selector: 'app-close-modal-button',
  templateUrl: './close-modal-button.component.html',
  styleUrls: ['./close-modal-button.component.scss'],
})
export class CloseModalButtonComponent implements OnInit {
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  constructor(private modalController: ModalController) {
  }

  ngOnInit() {
  }

  close() {
    this.modalController.dismiss();
    this.onClose.emit(true);
  }
}
