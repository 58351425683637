import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PopupBeforePayComponent } from './popup-before-pay.component';
import { ProductListModule } from '../../shop/productos/product-list/product-list.module';


@NgModule({
  declarations: [
    PopupBeforePayComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FlexLayoutModule,
    ProductListModule
  ]
})
export class PopupBeforePayModule {
}
