// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 5px 25px;
}

.description {
  padding: 10px 5px;
}

.highlight {
  font-weight: bold !important;
}

h3 {
  font-weight: bold;
}

.responsive-size {
  height: 400px;
}

@media only screen and (max-width: 540px) {
  .responsive-size {
    height: 800px;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbmZpcm0tcG9wb3Zlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLGlCQUFBO0FBQUY7O0FBRUE7RUFDRSxpQkFBQTtBQUNGOztBQUVBO0VBQ0UsNEJBQUE7QUFDRjs7QUFHQTtFQUNFLGlCQUFBO0FBQUY7O0FBRUE7RUFFRSxhQUFBO0FBQUY7O0FBRUE7RUFFRTtJQUVFLGFBQUE7RUFERjtBQUNGIiwiZmlsZSI6ImNvbmZpcm0tcG9wb3Zlci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIlxuLmNvbnRhaW5lcntcbiAgcGFkZGluZzogNXB4IDI1cHg7XG59XG4uZGVzY3JpcHRpb257XG4gIHBhZGRpbmc6IDEwcHggNXB4O1xufVxuXG4uaGlnaGxpZ2h0e1xuICBmb250LXdlaWdodDogYm9sZCAhaW1wb3J0YW50O1xuXG59XG5cbmgze1xuICBmb250LXdlaWdodDogYm9sZDtcbn0gIFxuLnJlc3BvbnNpdmUtc2l6ZXtcbiAgLy8gYmFja2dyb3VuZC1jb2xvcjogYmx1ZTtcbiAgaGVpZ2h0OiA0MDBweDtcbn1cbkBtZWRpYSBvbmx5IHNjcmVlbiBhbmQgKG1heC13aWR0aDogNTQwcHgpe1xuXG4gIC5yZXNwb25zaXZlLXNpemV7XG4gICAgLy8gYmFja2dyb3VuZC1jb2xvcjogcmVkO1xuICAgIGhlaWdodDogODAwcHg7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/confirm-popover/confirm-popover.component.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;AAAF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AAGA;EACE,iBAAA;AAAF;;AAEA;EAEE,aAAA;AAAF;;AAEA;EAEE;IAEE,aAAA;EADF;AACF;AACA,o5BAAo5B","sourcesContent":["\n.container{\n  padding: 5px 25px;\n}\n.description{\n  padding: 10px 5px;\n}\n\n.highlight{\n  font-weight: bold !important;\n\n}\n\nh3{\n  font-weight: bold;\n}  \n.responsive-size{\n  // background-color: blue;\n  height: 400px;\n}\n@media only screen and (max-width: 540px){\n\n  .responsive-size{\n    // background-color: red;\n    height: 800px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
