import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
@Component({
  selector: 'app-info-pasar-pagina',
  templateUrl: './info-pasar-pagina.component.html',
  styleUrls: ['./info-pasar-pagina.component.scss'],
})
export class InfoPasarPaginaComponent implements OnInit {
  @Input() test;

  isMobile: boolean = null;

  constructor(
    private popoverController: PopoverController,
    private platform: Platform
  ) { }

  ngOnInit() {
    // console.log("####hey test", this.test)

    this.checkDevice();
  }

  private checkDevice(){
    let platforms = this.platform.platforms();
    this.isMobile = !!(platforms.find( type => type == "mobile"));
  }


  public close():void{
   // console.log("Goodbay");
    this.popoverController.dismiss();
  }
}
