// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-checkbox::part(label) {
  white-space: break-spaces;
}

h1 {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 600;
  font-size: 22px;
}

p {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 400;
  font-size: 16px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbmZpcm0tY29tbWVudC1tb2RhbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFLHlCQUFBO0FBQUo7O0FBSUE7RUFDRSxnREFBQTtFQUNBLGdCQUFBO0VBQ0EsZUFBQTtBQURGOztBQUlBO0VBQ0UsZ0RBQUE7RUFDQSxnQkFBQTtFQUNBLGVBQUE7QUFERiIsImZpbGUiOiJjb25maXJtLWNvbW1lbnQtbW9kYWwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJpb24tY2hlY2tib3gge1xuICAmOjpwYXJ0KGxhYmVsKSB7XG4gICAgd2hpdGUtc3BhY2U6IGJyZWFrLXNwYWNlcztcbiAgfVxufVxuXG5oMSB7XG4gIGZvbnQtZmFtaWx5OiBcIkJhcmxvdyBTZW1pIENvbmRlbnNlZFwiLCBzYW5zLXNlcmlmO1xuICBmb250LXdlaWdodDogNjAwO1xuICBmb250LXNpemU6IDIycHg7XG59XG5cbnAge1xuICBmb250LWZhbWlseTogXCJCYXJsb3cgU2VtaSBDb25kZW5zZWRcIiwgc2Fucy1zZXJpZjtcbiAgZm9udC13ZWlnaHQ6IDQwMDtcbiAgZm9udC1zaXplOiAxNnB4O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/components/confirm-comment-modal/confirm-comment-modal.component.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;AAAJ;;AAIA;EACE,gDAAA;EACA,gBAAA;EACA,eAAA;AADF;;AAIA;EACE,gDAAA;EACA,gBAAA;EACA,eAAA;AADF;AACA,oxBAAoxB","sourcesContent":["ion-checkbox {\n  &::part(label) {\n    white-space: break-spaces;\n  }\n}\n\nh1 {\n  font-family: \"Barlow Semi Condensed\", sans-serif;\n  font-weight: 600;\n  font-size: 22px;\n}\n\np {\n  font-family: \"Barlow Semi Condensed\", sans-serif;\n  font-weight: 400;\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
