import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ProductService } from "../../shared/product.service";

@Component({
  selector: 'app-print-settings-labels',
  templateUrl: './print-settings-labels.component.html',
  styleUrls: ['./print-settings-labels.component.scss'],
})
export class PrintSettingsLabelsComponent implements OnInit, OnChanges {
  @Input() printSettings;
  @Input() files;
  @Input() size = 'normal';
  labels = [];

  constructor(public productService: ProductService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.printSettings) {
      this.updateLabels();
    }
  }

  updateLabels() {
    this.labels = this.productService.getNewPrintLabels(this.printSettings, this.files);
  }


}
