import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { UserService } from './../../core/user.service';
import { Component, Input, OnInit } from '@angular/core';
import { AcceptanceService } from 'src/app/shared/acceptance.service';

@Component({
  selector: 'app-login-card',
  templateUrl: './login-card.component.html',
  styleUrls: ['./login-card.component.scss'],
})
export class LoginCardComponent implements OnInit {
  loading;
  @Input() returnPath:string = '/'
  @Input() embassadorCase:boolean = false;

  constructor(
    public router: Router,
    private userService: UserService,
    private acceptanceService: AcceptanceService,
    private loadingController: LoadingController
  ) { }

  ngOnInit() {}

  async doFacebookLogin() {
    if (await this.userService.presentAcceptance()){
      this.presentLoading();
      this.userService.doSignupFacebook()
      .then((user) => {
       // console.log('===>> SUCCESS FB:');
        this.acceptanceService.userAcceptance(user);
        this.loading.dismiss();
        this.router.navigate([this.returnPath]);     
      })
      .catch(res => {
        console.log('===>> ERROR FB:');
        console.log(res);
        this.loading.dismiss();
      });
    } else {
      throw "error"; 
    }
  }  

  async doGoogleLogin(): Promise<any> {
    if (await this.userService.presentAcceptance()){
      this.presentLoading();
      this.userService.doSignupGoogle()
      .then((user:any) => {
        this.acceptanceService.userAcceptance(user)
        this.loading.dismiss();
        this.router.navigate([this.returnPath]);    
      })
      .catch(res => {
        console.error(res);
        this.loading.dismiss();
      });
    } else{
      throw "error"; 
    }
  }

  doFormLogin(): void {
    this.router.navigate(['/auth/login'],{ queryParams: { returnPath: this.returnPath}});
  }

  async presentLoading(){
    this.loading = await this.loadingController.create({
      message: 'Espera por favor...'
    });
    this.loading.present();
  }  
}
