// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.align {
  flex-flow: nowrap;
}

.info {
  font-size: 13px;
}

.check {
  min-width: 26px;
}

ion-icon {
  pointer-events: none;
}

.tooltip {
  line-height: 0 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoZWNrLWxhbWluYXRlZC1jb3Zlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGlCQUFBO0FBQ0Y7O0FBR0E7RUFDRSxlQUFBO0FBQUY7O0FBR0E7RUFDRSxlQUFBO0FBQUY7O0FBR0E7RUFDRSxvQkFBQTtBQUFGOztBQUdBO0VBQ0UseUJBQUE7QUFBRiIsImZpbGUiOiJjaGVjay1sYW1pbmF0ZWQtY292ZXIuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYWxpZ24ge1xuICBmbGV4LWZsb3c6IG5vd3JhcDtcbn1cblxuXG4uaW5mbyB7XG4gIGZvbnQtc2l6ZTogMTNweDtcbn1cblxuLmNoZWNrIHtcbiAgbWluLXdpZHRoOiAyNnB4O1xufVxuXG5pb24taWNvbiB7XG4gIHBvaW50ZXItZXZlbnRzOiBub25lO1xufVxuXG4udG9vbHRpcCB7XG4gIGxpbmUtaGVpZ2h0OiAwICFpbXBvcnRhbnQ7XG59XG5cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/home/check-laminated-cover/check-laminated-cover.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,oBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;AACA,4pBAA4pB","sourcesContent":[".align {\n  flex-flow: nowrap;\n}\n\n\n.info {\n  font-size: 13px;\n}\n\n.check {\n  min-width: 26px;\n}\n\nion-icon {\n  pointer-events: none;\n}\n\n.tooltip {\n  line-height: 0 !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
