import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TooltipModule, TooltipOptions } from '@cloudfactorydk/ng2-tooltip-directive';


import { BindingOptionsComponent } from './binding-options.component';
import { DefaultTooltipOptions } from '../default-tooltip-options';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    IonicModule,
    FlexLayoutModule,
    TooltipModule.forRoot(DefaultTooltipOptions as TooltipOptions),
  ],
  exports: [BindingOptionsComponent],
  declarations: [BindingOptionsComponent]
})
export class BindingOptionsModule {
}
