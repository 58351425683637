export const arrFeeType = ["share", "referrer", "coupon", "creator", "royalty"] as const;
export type FeeType =  typeof arrFeeType[number];

//Comisiones (de los settings o de algún cliente)
export interface Fees {
  share: number;
  referrer: number;
  coupon: number;
  creator: number;
  royalty: number;
}