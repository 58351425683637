import { Component, OnInit } from '@angular/core';
import { UserService } from '../core/user.service';
import { AcceptanceService } from '../shared/acceptance.service';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { CartService } from '../shared/cart.service';

@Component({
  selector: 'app-social-auth-options',
  templateUrl: './social-auth-options.component.html',
  styleUrls: ['./social-auth-options.component.scss'],
})
export class SocialAuthOptionsComponent implements OnInit {

  loading;
  returnPath = '/';

  constructor(
    private userService: UserService,
    private acceptanceService: AcceptanceService,
    public router: Router,
    private loadingController: LoadingController,
    private cartService: CartService
  ) { }

  ngOnInit() {}

  async doFacebookLogin() {
    if (await this.userService.presentAcceptance()) {
      this.presentLoading();
      this.userService.doSignupFacebook()
        .then((user) => {
          // console.log('===>> SUCCESS FB:');
          this.acceptanceService.userAcceptance(user)
          this.loading.dismiss();
          if (this.cartService.currentStep === 1) {
            this.router.navigate(['/cart']);
          } else {
            this.router.navigate([this.returnPath]);
          }
        })
        .catch(res => {
          console.log('===>> ERROR FB:');
          console.log(res);
          this.loading.dismiss();
        });
    } else {
      throw "error";
    }
  }


  async presentLoading() {
    this.loading = await this.loadingController.create({
      message: 'Espera por favor...'
    });
    this.loading.present();
  }

  async doGoogleLogin() {
    if (await this.userService.presentAcceptance()) {
      this.presentLoading();
      this.userService.doSignupGoogle()
        .then((user: any) => {
          this.acceptanceService.userAcceptance(user)
          this.loading.dismiss();
          if (this.cartService.currentStep === 1) {
            this.router.navigate(['/cart']);
          } else {
            this.router.navigate([this.returnPath]);
          }
        })
        .catch(res => {
          this.loading.dismiss();
        });
    } else {
      throw "error";
    }
  }

}
