///import { Platform } from '@ionic/angular';
import { Injectable, NgZone } from "@angular/core";
import { Observable, firstValueFrom, of } from "rxjs";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { UserModel } from "./user.model";
import firebase from "firebase/compat/app";
import firebaseCompat from '@firebase/app-compat';

//  const { Device } = Plugins;

import { AngularFireFunctions } from "@angular/fire/compat/functions";
//import {SignInResult} from './definitions';

// TODO SE DEBE REALIZAR LA CONFIGURACIÓN PARA HACER LOGIN CON FACEBOOK IGUAL QUE CON GOOGLE

export enum AuthProviders {
  Github = 0,
  Twitter = 1,
  Facebook = 2,
  Google = 3,
  Password = 4,
  Anonymous = 5,
  Custom = 6,
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // public authState$: Observable<any>;
  public authState$: Observable<firebase.User>;
  public authState: firebase.User;
  // public platform: any;
  // private authState$: Observable<firebase.User>;
  constructor(
    public afAuth: AngularFireAuth,
    private ngZone: NgZone,
    private functions: AngularFireFunctions
  ) // private userService : UserService
  {
    // firebase.auth().languageCode = 'fr';
    this.afAuth.useDeviceLanguage();
    this.authState$ = afAuth.authState;
    this.authState$.subscribe((user: firebase.User) => {
      this.authState = user;
    });
    //Esto seria para actualizar user
    this.afAuth.onAuthStateChanged((user) => {
      if (user) {
        if (user.isAnonymous === true) {
          // Redirect to a page for the user to give you their credentials.
        } else {
          // console.log("AuthService: Cambio de User", user)
          // Give access to the detail page.
        }
      }
    });
    this.initAuth();
  }

  async initAuth() {
    // const info = await Device.getInfo();
    // this.platform = info.platform;
  }
  // Returns true if user is logged in
  get authenticated(): boolean {
    return !!this.authState;
  }

  // Returns current user //deberia ser current auth
  get currentUser$(): any {
    return this.authState$;
  }

  // Returns current user UID
  // get currentUserId(): string {
  //   //eturn this.authenticated ? this.authState$.uid : '';
  // }
  createAnonymousUser(): Promise<any> {
    return this.afAuth.signInAnonymously();
  }

  doLogout() {
    return this.afAuth.signOut();
  }

  doEmailLogin(value) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth
        .signInWithEmailAndPassword(value.email, value.password)
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  }

  resetPasswordInit(email: string) {
    // send email from server so custom email template is used, instead of firebase's
    return firstValueFrom(
      this.functions
        .httpsCallable("passwordResetEmail")({ email })
    ) 

    // return new Promise(r => setTimeout(r, 5000));
    // return this.afAuth.sendPasswordResetEmail(email);
  }

  doLinkEmail(data) {
    var credential = firebaseCompat.auth.EmailAuthProvider.credential(
      data.email,
      data.password
    );
    return this.authState.linkWithCredential(credential).then((uC) => {
      const user: UserModel = new UserModel();
      user.emailVerified = uC.user.emailVerified;
      user.regStatus = UserModel.RegStatus.Location;
      user.uid = uC.user.uid;
      user.provider = {
        ["email"]: uC.user.email,
      };
      return user;
    });
  }

  doRegister(credential) {
    return this.afAuth
      .createUserWithEmailAndPassword(credential.email, credential.password)
      .then((uC) => {
        const user: UserModel = new UserModel();
        // user.displayName = uC.user.displayName;
        user.email = uC.user.email;
        user.emailVerified = uC.user.emailVerified;
        user.phoneNumber = uC.user.phoneNumber;
        user.phoneNumberVerified = false;
        user.regStatus = UserModel.RegStatus.Location;
        // user.photoURL = uC.user.photoURL;
        user.uid = uC.user.uid;
        user.provider = {
          ["email"]: uC.user.email,
        };
        return user;
      });
  }

  doLogin(service) {
    return this.goPopupLogin(service);
  }

  mapProviderToUser(uC) {
    let isNew = uC.isNew;
    let service = uC.additionalUserInfo.providerId;

    //console.log('mapProviderToUser');

    const user: UserModel = new UserModel();
    user.displayName = uC.user.displayName || "";
    user.email = uC.user.email || "";
    user.emailVerified = uC.user.emailVerified || false;
    user.phoneNumber = uC.user.phoneNumber || "";
    user.phoneNumberVerified = false;
    user.photoURL = uC.user.photoURL || "";
    user.uid = uC.user.uid;
    user.regStatus = UserModel.RegStatus.Name;
    user.provider = {
      [service]:
        uC.additionalUserInfo.profile["id"] ||
        uC.additionalUserInfo.profile["sub"], // TODO: esto está diferente cuando se usa el pop up
    };
    switch (service) {
      case "google.com":
        user.firstName = uC.additionalUserInfo.profile["given_name"] || "";
        user.lastName = uC.additionalUserInfo.profile["family_name"] || "";
        break;
      case "facebook.com":
        user.firstName = uC.additionalUserInfo.profile["first_name"] || "";
        user.lastName = uC.additionalUserInfo.profile["last_name"] || "";
        if (!user.email)
          user.email = uC.additionalUserInfo.profile["email"] || ""; // MN why is this?? it should come in uC.user.email
        break;
    }
    return user;
  }

  goPopupLogin(service) {
    let provider = null;
    switch (service) {
      case "google.com":
        provider = new firebaseCompat.auth.GoogleAuthProvider();
        provider.defaultLanguageCode = "es";
        provider.addScope("profile");
        provider.addScope("email");
        break;
      case "facebook.com":
        provider = new firebaseCompat.auth.FacebookAuthProvider();
        // provider = new FacebookAuthProvider();
        provider.addScope("email");
        break;
      // default:
      //   return;
    }

    return this.afAuth
      .signInWithPopup(provider)
      .then((uC) => {
        //console.log('===>> UC:');
        //console.log(uC);
        const user: UserModel = new UserModel();
        user.displayName = uC.user.displayName || "";
        user.email = uC.user.email || "";
        user.emailVerified = uC.user.emailVerified || false;
        user.phoneNumber = uC.user.phoneNumber || "";
        user.phoneNumberVerified = false;
        user.photoURL = uC.user.photoURL || "";
        user.uid = uC.user.uid;
        user.regStatus = UserModel.RegStatus.Name;
        user.provider = {
          // TODO: en el otro uso de sign up usa id || sub, aquí no?
          [uC.additionalUserInfo.providerId]:
            uC.additionalUserInfo.profile["id"],
        };
        switch (service) {
          case "google.com":
            user.firstName = uC.additionalUserInfo.profile["given_name"] || "";
            user.lastName = uC.additionalUserInfo.profile["family_name"] || "";
            if (!user.email)
              user.email = uC.additionalUserInfo.profile["email"] || ""; // MN why is this?? it should come in uC.user.email
            break;
          case "facebook.com":
            user.firstName = uC.additionalUserInfo.profile["first_name"] || "";
            user.lastName = uC.additionalUserInfo.profile["last_name"] || "";
            if (!user.email)
              user.email = uC.additionalUserInfo.profile["email"] || ""; // MN why is this?? it should come in uC.user.email
            break;
        }
        return user;
      })
      .catch((err) => {
        // MN: propagate error
        console.log("catch signInWithPopup");
        console.log(err);
      });
  }
}
