import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QtyStepperComponent } from './qty-stepper.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [
    CommonModule, IonicModule
  ],
  exports: [QtyStepperComponent],
  declarations: [QtyStepperComponent]
})
export class QtyStepperModule { }
