import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AppService } from 'src/app/shared/app.service';
import { PrintProductBlService } from 'src/app/shared/services/printProductBL';
import {  cloneDeep } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Product, ProductSubType } from 'src/app/models/product.model';
import { map, tap } from 'rxjs/operators';
import { PrintProductPageModel, ProductPageModel } from 'src/app/models/product-ui.model';
import { PrintProduct } from 'src/app/models/printProduct.model';
import { ProductBlService } from 'src/app/shared/services/productBl.service';
import { FileService } from 'src/app/shared/file.service';
import { PrintFile, Settings, _PrintFile } from 'src/app/models/models';


@Component({
  selector: 'app-partial-document-detail',
  templateUrl: './partial-document-detail.component.html',
  styleUrls: ['./partial-document-detail.component.scss'],
})
export class PartialDocumentDetailComponent implements OnInit, OnChanges {
  @Input() product
  @Input() isPage = true;
  @Input() pictureUrl = "";
  @Output() changes = new EventEmitter<any>()
  _qty = new BehaviorSubject<number>(1);

  settings
  files;    
  constructor(
    private printProductBlService: PrintProductBlService,
    public appService: AppService,
    private productBlService: ProductBlService,
    private fileService: FileService
  ) { 
    this.settings = this.appService.getPrintSettings();
  }

  ngOnInit() {  
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.printProductBlService.setPrintProduct(this.product)
    if (this.product){
      this.changes.emit({
        type:'color', 
        payload:{
          product: this.product,
          settings: this.product.printingGroup.printSettings
        }
      })       
    } else{
    }
  }
  
  setPrintProduct = (printProduct) => {
    const files = [];
    // this.printProduct = printProduct;
    if (printProduct && printProduct.id){
      //initSettings debería normalizar y ajustar ordenes viejas a nuevo formato
      this.settings = this.printProductBlService.initSettings(printProduct.printingGroup.printSettings);
      printProduct.printingGroup.files.forEach(_file => {
        files.push(
          {
            file : null, 
            isUploaded : true,
            progress: 0        
          }
        ) 
      } )
      this.files = files || [];
    }    
  }  

  settingsChanged$ = this.printProductBlService.settingsChanged$;

  printProduct$:Observable<Product> = this.printProductBlService.printProduct$.pipe(
    tap(this.setPrintProduct)
  )  
  savedFile$ = this.printProductBlService.savedFile$;

  productSelected$ = combineLatest([this.printProduct$, this._qty, this.settingsChanged$, this.savedFile$]).pipe(
    map(([product, qty, settings, savedFile])=>{
      // console.log("product...", product)
      if (product){
        this.printProductBlService.refreshPrice(product, this.files);
      }
      let uiProduct:PrintProductPageModel = cloneDeep(product) as any;
      if (product){
        // const settings =  product.printingGroup.printSettings;
        // const file = savedFile as _PrintFile;
        // const thumb = this.fileService.getThumbFile(file, settings);
        // uiProduct.pictureUrl =  thumb ? thumb.url :  product.pictureUrl
        uiProduct.royalty = product.royalty ?? 0;
        uiProduct.unitPrice = PrintProduct.unitPrice(product);
        uiProduct.price = PrintProduct.saledPrice(product);
        uiProduct.total = uiProduct.unitPrice.amt * qty + uiProduct.royalty
        uiProduct.qty = qty;
        uiProduct.canAdd = true;
      }
      return uiProduct;
    })
  )
  
  onQtyChange(event){
    this._qty.next(event.qty);
  }  
  onAdd(_product:any){
    const product =  this.printProductBlService.printProduct() as any;
    // console.log("ADD", product)
    product.pictureUrl = this.pictureUrl;
    this.productBlService.addProduct(product, this._qty.value); 
    this.changes.emit({
      type:'add', 
      payload:{
        product,
        qty: this._qty.value
      }
    })

  }
  settingOptionSelect(event){
    this.printProductBlService.setprintingGroupSettings(event.setting, event.option, this.settings);
    const _printProduct = this.printProductBlService.printProduct();
    const _settings =  _printProduct.printingGroup.printSettings;
    if (event.setting===Settings.COLOR){ 
      this.changes.emit({
        type:'color', 
        payload:{
          product: _printProduct,
          settings: _settings
        }
      })      
    }
  }

  protected readonly ProductSubType = ProductSubType;
}
