import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {

  @Input() searchValue;
  @Output() searchEmitter = new EventEmitter<KeyboardEvent>();

  constructor() {
  }

  ngOnInit() {
  }

  search(event: KeyboardEvent) {
    this.searchEmitter.emit(event);
  }
}
