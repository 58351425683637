import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import ProductCardComponent from './product-card.component';
import { IonicModule } from '@ionic/angular';
import { ProductCardInfoModule } from './product-card-info/product-card-info.module';
import { ProductPriceModule } from './product-price/product-price.module';
import { ProductLabelModule } from './product-label/product-label.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    ProductCardComponent,
    ProductCardComponent,
  ],
  exports: [
    ProductCardComponent,
    ProductCardComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FlexLayoutModule,
    ProductCardInfoModule,
    ProductPriceModule,
    ProductLabelModule,
  ]
})
export class ProductCardModule {
}
