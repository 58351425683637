import { uidI } from '../shared/utils/utils';
import { Discount, FeeType, FullPrice, Price } from './models';
import { Product, ProductSubType, ProductType } from './product.model';

export class ShoppingCart {
  createdAt: Date;
  updatedAt: Date;
  userId?: string; // reference to user document. may be anonymous
  items: ShoppingCart.OrderItem[];
  shipping: {
    weight: number;
    price: Price; //Precio que se cobra d eenvío
    fullPrice?: Price; //Precio full sin considerar el desceunto 
  };
  price: FullPrice; // total to pay
  discounts: Array<Discount>;
}


export namespace ShoppingCart {

  import saledPrice = Product.saledPrice;

  export interface OrderItemUi extends OrderItem {
    _files?: any[];
  }

  export class OrderItem {
    cartId: string;
    notEditable?: boolean; //Si no se puede editar
    product: Product._Product; // reference to Product
    qty: number; // quantity: total amount of this item (print producs: duplicates)
    price: Price; // item price: qty * product.unitPrice
    //id?: string; // optional id, to be able to be identified as a parent
    //parendId?: string; // optional id, if this item belongs to a parent item
    payload?: {
      fee: {
        userId: string;
        type: FeeType;
      }
    };
    unavailable?: boolean
  }

  export const newOrderItem = (product: Product, qty: number, oldData?): OrderItem => {
    const _product = Product.new_Product(product);
    // console.log("product", product);
    // console.log("_product", _product);
    const cartId = uidI();
    _product.unitPrice = product['price'] ?? saledPrice(product.subType === ProductSubType.CUSTOM && oldData ? oldData : product);
    _product.categories = product.categories ?? null;
    const price = { ...saledPrice(product.subType === ProductSubType.CUSTOM && oldData ? oldData : product) };
    // const price = {...product['price']};
    const extraPrice = product.extra?.price ?? 0;
    const royalty = product.royalty ?? 0;
    price.amt = (qty * (extraPrice + price.amt)) + royalty;
    if (!_product.type) {
      _product.type = ProductType.STORE;
    }
    if (oldData) {
      _product.title = oldData.title;
      _product.categories = oldData.categories;
      if (product.subType === ProductSubType.CUSTOM) {
        _product.customElementsSelected = oldData?.customElementsSelected;
      }
    }
    return { cartId, product: _product, qty, price };
  };

  /*export const newOrderItem = (product: Product._Product, qty: number, oldData?): OrderItem => {
    const _product = Product.new_Product(product);
    // console.log("product", product);
    // console.log("_product", _product);
    const cartId = uidI();
    _product.unitPrice = product['price'] ?? saledPrice(product);
    _product.categories = product.categories ?? null;
    const price = { ...saledPrice(product) };
    // const price = {...product['price']};
    const extraPrice = product.extra?.price ?? 0;
    const royalty = product.royalty ?? 0;
    price.amt = (qty * (extraPrice + price.amt)) + royalty;
    if (!_product.type) {
      _product.type = ProductType.STORE;
    }
    if (oldData) {
      _product.title = oldData.title;
      _product.categories = oldData.categories;
    }
    return { cartId, product: _product, qty, price };
  };*/
}
