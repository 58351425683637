import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

export interface Shareds {
  name: string
  iconName: string
  color: string
  type: SharedsType //---> el tipo de dato colocado
}
export enum SharedsType {
  WHATSAPP = 'whatsapp',
  MAIL = 'mailto',
  COPY = 'copy'
}

@Component({
  selector: 'app-browser-share',
  templateUrl: './browser-share.component.html',
  styleUrls: ['./browser-share.component.scss'],
})
export class BrowserShareComponent implements OnInit {

  shareList: Array<Shareds> = [
    { name: 'Compartir por Whatsapp', iconName:'../../../assets/icon/whatsapp-doodle.svg', color:'whatsapp', type: SharedsType.WHATSAPP },
    { name: 'Compartir por email',     iconName:'../../../assets/icon/email.svg', color:'mailto', type: SharedsType.MAIL },
    { name: 'Copiar al portapapeles', iconName:'../../../assets/icon/copiar.svg', color:'clipboard',type: SharedsType.COPY },
  ]

  constructor(
    private popoverController: PopoverController ,
  ) { }

  ngOnInit() {}

  clickOn(shareType:string){
    this.popoverController.dismiss(shareType);
  }

  close() {
    this.popoverController.dismiss();
  }
}