// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// import { version } from "../../package.json";
import packageInfo from '../../package.json';

export const environment = {
  production: false,
  version: packageInfo.version,
  url: window.location.href.split('/'),
  conditionFeeUrl: 'https://copyfly.es/terminos-de-servicio/',
  firebaseConfig: {
    apiKey: 'AIzaSyBgV28UTWs2Qp0q5nZZpbskXQ-82wNS3vA',
    authDomain: 'app-develop.copyfly.es',
    // authDomain: "copias-a-domicilio-develop.firebaseapp.com",
    databaseURL: 'https://copias-a-domicilio-develop.firebaseio.com',
    projectId: 'copias-a-domicilio-develop',
    storageBucket: 'copias-a-domicilio-develop.appspot.com',
    messagingSenderId: '461160713718',
    appId: '1:461160713718:web:ccf7b3d012d567f3ce4bd7',
    // measurementId: 'G-HSFRGYHE9Z'
    measurementId: "G-PFW36JCVMX"
  },
  google: {
    apiKey: 'AIzaSyBgV28UTWs2Qp0q5nZZpbskXQ-82wNS3vA',
    clientId: '461160713718-elob5iieajmli4hi12sm9pf65cuehdg8.apps.googleusercontent.com',
  },
  maps: {
    apiKey: 'AIzaSyBMdMZJSdLbT6obxcaHNlssB1l8wkBWI4Y'
  },
  elasticSearch: {
    url: 'https://api-develop.copyfly.es/'
  },
  appKeydropbox: 'f4t9a46k946ttuk',
  apiUrl: 'https://api-develop.copyfly.es/',
  redsysUrl: 'https://sis-t.redsys.es:25443/sis/realizarPago',
  googleAnalyticsKey: 'UA-176779913-2',
  policyUrl: 'https://copyfly.es/privacidad/',
  tosUrl: 'https://copyfly.es/terminos-de-servicio/',

  rollbar: {
    accessToken: '09893d1d3fbb4c60b4f23117e4526d02'
  },
  options: {
    creatorsActive: false,
    landingShop: '/papeleria',
    categoriesFilter: true,
    vendorsFilter: false,
    featuredFilter: true,
    promotionProductSlug: 'camisetas-guay'
  },
  adobe: {
    // clientId: 'fe557e3b9b6b4c4fafb0407cfe543a44',
    clientId: '04746e8103974b22939ae8b11271720c',
  },
  encryptionKey: "A%eXc+FMSJ2VNH^9X#sIeNUx"
};


//http://localhost:8100/biblioteca/detailed-game-design


export const environment_prod = {
  production: false,
  version: packageInfo.version,
  url: window.location.href.split('/'),
  conditionFeeUrl: 'https://copyfly.es/terminos-de-servicio/',
  firebaseConfig: {
    apiKey: 'AIzaSyAwFsf7Od_Uk9Ms0pVqxQcN64THe0IyEhE',
    authDomain: 'app.copyfly.es',
    // authDomain: "copias-a-domicilio.firebaseapp.com",
    databaseURL: 'https://copias-a-domicilio.firebaseio.com',
    projectId: 'copias-a-domicilio',
    storageBucket: 'copias-a-domicilio.appspot.com',
    messagingSenderId: '200026976959',
    appId: '1:200026976959:web:857d9349afd2470c210c0a',
    // measurementId: "G-PEXD7JF2TX"
    measurementId: 'G-S4WDR924GK'

  },
  elasticSearch: {
    url: 'https://api.copyfly.es/'
  },
  apiUrl: 'https://api.copyfly.es/',
  redsysUrl: 'https://sis.redsys.es/sis/realizarPago',
  googleAnalyticsKey: 'UA-176779913-1',
  policyUrl: 'https://copyfly.es/privacidad/',
  tosUrl: 'https://copyfly.es/terminos-de-servicio/',
  rollbar: {
    accessToken: '09893d1d3fbb4c60b4f23117e4526d02'
  },
  // options:{
  //   creatorsActive: true,
  //   landingShop: '/productos',
  //   categoriesFilter: false,
  //   vendorsFilter: false,
  //   featuredFilter: false
  // }    
  options: {
    creatorsActive: false,
    landingShop: '/papeleria',
    categoriesFilter: false,
    vendorsFilter: false,
    featuredFilter: false
  },
  adobe: {
    clientId: 'e94fa94d9d0d4cbc8e19bbf9b0f3e92b',
  }
};
/**
 options:{
 creatorsActive: false,
 landingShop: '/tienda',
 categoriesFilter: false,
 }
 */

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
//  import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
