import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ProductList } from 'src/app/models/product-ui.model';
import { Product } from 'src/app/models/product.model';

type labelType = 'stock' | 'exclusive' | 'featured' | 'discount' | 'presale';

type label = {
  icon?: string,
  label: string,
  info: string,
  color: string
}

type labelDef = {
  [x in labelType]: label;
};

const LABEL_DEFINITIONS: labelDef = {
  "stock": {
    // icon: 'sad-outline',
    label: 'agotado',
    info: "Agotado",
    color: "dark"
  },
  "exclusive": {
    // icon: 'paper-plane-outline',
    label: 'exclusivo',
    info: "Exclusivo",
    color: "primary"
  },
  "featured": {
    // icon: 'happy-outline',
    label: 'destacado',
    info: "¡Destacado!",
    color: "secondary"
  },
  "discount": {
    // icon: 'cash-outline',
    label: 'descuento',
    info: "Descuento",
    color: "tertiary"
  },
  "presale": {
    // icon: 'pricetag-outline',
    label: 'preventa',
    info: "Preventa",
    color: "danger"
  }
}

@Component({
  selector: 'app-product-label',
  templateUrl: './product-label.component.html',
  styleUrls: ['./product-label.component.scss']
})
export default class ProductLabelComponent implements OnInit, OnChanges {

  @Input() product: ProductList;
  @Input() highestDiscount;
  @Input() smallLabels = false;
  @Input() relativePosition = false;
  discountLabel = '';
  public labels: label[];

  constructor() {
  }

  ngOnInit(): void {
    if (this.product.discount && !this.product.hasVariant) {
      this.discountLabel = `-${this.product.discount}%`;
    }

    if (this.highestDiscount > 0) {
      this.discountLabel = `Hasta -${this.highestDiscount}%`;
      if (!this.labels.includes(LABEL_DEFINITIONS.discount)) {
        this.labels.push(LABEL_DEFINITIONS.discount);
      }
    }
  }

  ngOnChanges(changes) {
    const labelTypes = this.getLabelTypes(this.product)
    this.labels = [];
    labelTypes.forEach(type => {
      this.labels.push(LABEL_DEFINITIONS[type])
    });

    if (changes.highestDiscount && !changes.highestDiscount.firstChange) {
      const maxDiscount = changes.highestDiscount?.currentValue ?? null;
      if (maxDiscount > 0) {
        this.discountLabel = `Hasta -${maxDiscount}%`;
        if (!this.labels.includes(LABEL_DEFINITIONS.discount)) {
          this.labels.push(LABEL_DEFINITIONS.discount);
        }
      }
    }
  }

  /**
   * @returns the current status of product based on teh information in product
   */
  private getLabelTypes(product): labelType[] {
    const types = [];
    if (Product.isAllProductSoldOut(product)) {
      types.push('stock');
      return types;
    }
    if (product.discount > 0 || this.highestDiscount) types.push('discount');
    if (product.presale) types.push('presale');
    if (product.exclusive) {
      types.push('exclusive');
    } else if (product.featured) {
      types.push('featured');
    }
    //Si no cumple con ninguna no se muestra la etiqueta
    return types;
  }

}
