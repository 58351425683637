import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PartialDocumentDetailComponent } from './partial-document-detail.component';
import { ProductVariantsModule } from 'src/app/shop/productos/product-variants/product-variants.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OptionsButtonModule } from 'src/app/home/options-button/options-button.module';
import { QtyStepperModule } from 'src/app/shared/shop/qty-stepper/qty-stepper.module';
import { BindingOptionsModule } from 'src/app/home/binding-options/binding-options.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule,
    FlexLayoutModule,
    QtyStepperModule,
    ProductVariantsModule,
    OptionsButtonModule,
    BindingOptionsModule,
    RouterModule
  ],
  declarations: [PartialDocumentDetailComponent],
  exports: [PartialDocumentDetailComponent]
})
export class PartialDocumentDetailModule {}
