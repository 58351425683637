import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ShoppingCart } from '../models/cart.model';
import { _PrintFile, Finishing, Settings } from '../models/models';
import { AppService } from '../shared/app.service';
import { FileService } from '../shared/file.service';
import { ProductService } from '../shared/product.service';

@Injectable({
  providedIn: 'root'
})
export class PrintProductStateService {
  private readonly _printProduct = new BehaviorSubject<ShoppingCart.OrderItemUi>(null);
  readonly printProduct$ = this._printProduct.asObservable();

  private readonly _settingsChanged = new BehaviorSubject<Date>(new Date());
  readonly settingsChanged$ = this._settingsChanged.asObservable();

  printingGroupFiles: _PrintFile[];

  constructor(
    private appService: AppService,
    private productService: ProductService,
    private fileService: FileService,
  ) {
  }

  newPrintProduct(printProduct: ShoppingCart.OrderItemUi = null) {
    // console.log("new PrintProductState", printProduct);
    this._printProduct.next(printProduct || this.productService.newPrintProductItem());
    // console.log("print stae ", this._printProduct.value);
    this._settingsChanged.next(new Date());
  }

  // setprintingGroupSettings(setting, option, settings){
  //   const _printProduct = {...this._printProduct.value};
  //   //set('product.printingGroup.printSettings')
  //   _printProduct.product.printingGroup.printSettings[setting] = settings[setting].options[option - 1].value;
  //   const printProduct = set('product.printingGroup.printSettings[setting]', settings[setting].options[option - 1].value,this._printProduct.value );
  //   if(setting==='coverColor'){
  //     printProduct.product.printingGroup.files.forEach(file => file.coverColor = settings[setting].options[option - 1].value)
  //   }    
  //   this._printProduct.next(printProduct);
  // }
  setprintingGroupSettings(setting, option, settings) {
    const _printProduct = this._printProduct.value;
    //set('product.printingGroup.printSettings')
    let printSettings = _printProduct.product.printingGroup.printSettings;
    printSettings[setting] = settings[setting].options[option - 1].value;
    if (printSettings.finishing === 'laminatedA4' || printSettings.finishing === 'laminatedA3') {
      if (printSettings.pageSize === 'A3') {
        printSettings.finishing = Finishing.LAMINATEDA3;
      } else if (printSettings.pageSize === 'A4') {
        printSettings.finishing = Finishing.LAMINATEDA4;
      }
    }
    const autoRotate = settings[Settings.PAGES_PER_SHEET].selected === 1 && printSettings[Settings.FINISHING] !== 'stapled'

    printSettings['autoRotate'] = autoRotate;
    // const printProduct = set('product.printingGroup.printSettings[setting]', settings[setting].options[option - 1].value,this._printProduct.value );

    //JA temporal this._printProduct.next(_printProduct);
    this._settingsChanged.next(new Date());
  }

  refresh() {
    // const _printProduct = this._printProduct.value;
    // this._printProduct.next(_printProduct);
    this._settingsChanged.next(new Date());
  }


}
