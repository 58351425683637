// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  font-size: 18px;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}

.closeDiv {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-end;
  align-items: center;
  flex: 1 1 50px;
  max-height: 50px;
  min-height: 50px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFsZXJ0LW1vZGFsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBRUUsZUFBQTtFQUNBLGtCQUFBO0VBRUEsbUJBQUE7RUFDQSx1QkFBQTtFQUNBLGlCQUFBO0FBREY7O0FBSUE7RUFDRSxtQkFBQTtFQUNBLHNCQUFBO0VBQ0EsYUFBQTtFQUNBLDhCQUFBO0VBQ0EsbUJBQUE7RUFDQSxjQUFBO0VBQ0EsZ0JBQUE7RUFDQSxnQkFBQTtBQURGIiwiZmlsZSI6ImFsZXJ0LW1vZGFsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsicCB7XG4gIC8vY29sb3I6IHJlZDtcbiAgZm9udC1zaXplOiAxOHB4O1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gIC8vZGlzcGxheTogZmxleDtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIG1pbi1oZWlnaHQ6IDEwMHB4O1xufVxuXG4uY2xvc2VEaXYge1xuICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICBib3gtc2l6aW5nOiBib3JkZXItYm94O1xuICBkaXNwbGF5OiBmbGV4O1xuICBwbGFjZS1jb250ZW50OiBjZW50ZXIgZmxleC1lbmQ7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGZsZXg6IDEgMSA1MHB4O1xuICBtYXgtaGVpZ2h0OiA1MHB4O1xuICBtaW4taGVpZ2h0OiA1MHB4O1xufVxuXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/alert-modal/alert-modal.component.scss"],"names":[],"mappings":"AAAA;EAEE,eAAA;EACA,kBAAA;EAEA,mBAAA;EACA,uBAAA;EACA,iBAAA;AADF;;AAIA;EACE,mBAAA;EACA,sBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;AADF;AACA,g7BAAg7B","sourcesContent":["p {\n  //color: red;\n  font-size: 18px;\n  text-align: center;\n  //display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 100px;\n}\n\n.closeDiv {\n  flex-direction: row;\n  box-sizing: border-box;\n  display: flex;\n  place-content: center flex-end;\n  align-items: center;\n  flex: 1 1 50px;\n  max-height: 50px;\n  min-height: 50px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
