import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip-info-stapling',
  templateUrl: './tooltip-info-stapling.component.html',
  styleUrls: ['./tooltip-info-stapling.component.scss'],
})
export class TooltipInfoStaplingComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
