import {Injectable} from '@angular/core';
import {LoadingController, PopoverController} from '@ionic/angular';
import {BehaviorSubject, Observable} from 'rxjs';

declare const Dropbox: any;

@Injectable({
  providedIn: 'root'
})
export class DropboxPickerService {

  pickerCallback;
  response$ = new BehaviorSubject<any>([]);
  loading: HTMLIonLoadingElement;
  options = {

    // Required. Called when a user selects an item in the Chooser.
    success: (files) => {
      this.pickerResponse(files);
    },

    // Optional. Called when the user closes the dialog without selecting a file
    // and does not include any parameters.
    cancel: function() {

    },

    // Optional. "preview" (default) is a preview link to the document for sharing,
    // "direct" is an expiring link to download the contents of the file. For more
    // information about link types, see Link types below.
    linkType: 'direct', // or "direct"

    // Optional. A value of false (default) limits selection to a single file, while
    // true enables multiple file selection.
    multiselect: true, // or true

    // Optional. This is a list of file extensions. If specified, the user will
    // only be able to select files with these extensions. You may also specify
    // file types, such as "video" or "images" in the list. For more information,
    // see File types below. By default, all extensions are allowed.
    // extensions: ['.pdf', '.doc', '.docx'],
    extensions: ['.pdf'],

    // Optional. A value of false (default) limits selection to files,
    // while true allows the user to select both folders and files.
    // You cannot specify `linkType: "direct"` when using `folderselect: true`.
    folderselect: false, // or true

    // Optional. A limit on the size of each file that may be selected, in bytes.
    // If specified, the user will only be able to select files with size
    // less than or equal to this limit.
    // For the purposes of this option, folders have size zero.
    sizeLimit: 250 * 1024 * 1024, // 250Mb or any positive number
  };

  constructor(private loadingController: LoadingController, private popoverController: PopoverController) {
    this.createLoader();
  }

  async createLoader() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Cargando archivos...',
      spinner: 'bubbles'
    });
  }

  open() {
    Dropbox.choose(this.options);
  }

  getResponse(): Observable<File[]> {
    return this.response$.asObservable();
  }

  async pickerResponse(responseFiles) {
    this.loading.present();
    const files = await this.getFiles(responseFiles);
    this.loading.dismiss();
    this.response$.next(files);
  }

  async getFiles(files: any) {
    return new Promise(resolve => {
      const prom = files.map(file => {
          return new Promise(resolve => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', file.link, true);
            xhr.responseType = 'blob';
            xhr.onload = () => {
              // Get the response Blob.
              const blob = xhr.response;
              resolve(new File([blob], file.name, {type: 'application/pdf'}));
            };
            // Send the XHR request.
            xhr.send();
          });
        }
      );
      Promise.all(prom).then(files => {
        resolve(files);
      });
    });
  }
}
