import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  templateUrl: './acceptance-modal.component.html',
  styleUrls: ['./acceptance-modal.component.css']
})
export class AcceptanceModalComponent implements OnInit {
  @Input() isRecheck: boolean = false;
  env = environment;
  accountForm: UntypedFormGroup;

  constructor(
    private modalCtrl: ModalController,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.accountForm = this.fb.group({
      generalTerms: [false, Validators.requiredTrue]
    });    
  }

  onClose(data?){
    this.modalCtrl.dismiss(data);
  }

}
