import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip-info-folders',
  templateUrl: './tooltip-info-folders.component.html',
  styleUrls: ['./tooltip-info-folders.component.scss'],
})
export class TooltipInfoFoldersComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
