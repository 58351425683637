// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  height: 100%;
  padding: 5px;
}

ion-button {
  height: 32px;
}
ion-button::part(native) {
  margin: 0 !important;
  padding: 0 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNsb3NlLW1vZGFsLWJ1dHRvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFlBQUE7RUFDQSxZQUFBO0FBQ0Y7O0FBRUE7RUFLRSxZQUFBO0FBSEY7QUFERTtFQUNFLG9CQUFBO0VBQ0EscUJBQUE7QUFHSiIsImZpbGUiOiJjbG9zZS1tb2RhbC1idXR0b24uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJpbWcge1xuICBoZWlnaHQ6IDEwMCU7XG4gIHBhZGRpbmc6IDVweDtcbn1cblxuaW9uLWJ1dHRvbiB7XG4gICY6OnBhcnQobmF0aXZlKSB7XG4gICAgbWFyZ2luOiAwICFpbXBvcnRhbnQ7XG4gICAgcGFkZGluZzogMCAhaW1wb3J0YW50O1xuICB9XG4gIGhlaWdodDogMzJweDs7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/close-modal-button/close-modal-button.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;AACF;;AAEA;EAKE,YAAA;AAHF;AADE;EACE,oBAAA;EACA,qBAAA;AAGJ;AACA,okBAAokB","sourcesContent":["img {\n  height: 100%;\n  padding: 5px;\n}\n\nion-button {\n  &::part(native) {\n    margin: 0 !important;\n    padding: 0 !important;\n  }\n  height: 32px;;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
