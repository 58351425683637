import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-check-color-cover',
  templateUrl: './check-color-cover.component.html',
  styleUrls: ['./check-color-cover.component.scss'],
})
export class CheckColorCoverComponent implements OnInit {
  @Input() settings;
  @Input() settingOption;
  @Input() isCoverIndeterminated;
  @Input() disabled;
  @Output() optionSelect = new EventEmitter<any>();


  //Icono informativo
  infoIcon = {
    name: "information-circle-outline",
    color: "primary",
    tooltip: "Se imprimirá todo el documento en blanco y negro según la configuración que hayas seleccionado, y se añadirá la primera página a color en un folio separado, como portada a página completa.",
    size: "small"
  }

  constructor() {
  }

  ngOnInit() {
  }

  selectOption(event, checkbox) {
    setTimeout(() => {
      const prev = this.settings[this.settingOption].selected;
      this.settings[this.settingOption].selected = checkbox.checked ? 2 : 1;
      this.optionSelect.emit({ setting: this.settingOption, option: this.settings[this.settingOption].selected, prev });
    }, 100)
  }
}
