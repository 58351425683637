import { Injectable } from '@angular/core';
import {  from, Observable } from 'rxjs';
import {  map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { DocumentList } from 'src/app/models/document-ui.model';
import { PrintProduct } from 'src/app/models/printProduct.model';
import { newProductList, ProductPageModel } from 'src/app/models/product-ui.model';
import { Product,  ProductType } from 'src/app/models/product.model';
import { CartService } from '../cart.service';
import { ProductDbService } from '../database/product-db.service';

@Injectable({
  providedIn: 'root'
})
export class ProductBlService {

  constructor(
    private productDbService: ProductDbService,
    private cartService: CartService
  ) { }

  addProduct(productPage: ProductPageModel, _qty: number) {
    const qty = productPage.qty ?? _qty

    this.cartService.setProductCart(productPage, qty);
  }

  product$ = (id) => this.productDbService.get$(id);

  getVariants$(id: string) {
    return this.productDbService.getVariants$(id).pipe(
      map(variants => variants.map(this.toVariantUI))
    )
  }
  sortedVariants$ = (product) => this.getVariants$(product.id).pipe(
    map(variants => {
      return product.variantCount && product.variants ? product.variants.map(variantId => variants.find(v => v.id === variantId)) : []
    }),
    shareReplay(1)
  )

  productWithVariants$ = (id) => this.product$(id).pipe(
    mergeMap(
      product => this.sortedVariants$(product).pipe(
        map(variants => ({ ...product, variants }))
        // if (product.type === 'store' && produc)
      ))
  )

  getBySlug$(slug) {
    return this.productDbService.getBySlug$(slug).pipe(
      mergeMap(
        product => this.sortedVariants$(product).pipe(
          map(variants => ({ ...product, variants }))
          // if (product.type === 'store' && produc)
        ))
    );
  }


  private toVariantUI = (variant) => {
    const variantUI = this.toBaseProductUI(variant);
    return variantUI;
  }
  private toBaseProductUI = (product) => {
    const productUI = product;
    // ['unitPrice', 'cost'].forEach(property => {
    //   productUI[property] = productUI[property]?.amt ?? 0;
    // });    
    return productUI;
  }

  /**
   * Returns a qty of products
   */
  getQty(qty: number) {
    return
  }

  //TODO llevar a formato getProducts
  // mirar docuemnt List
  //(( crear estructura de option))
  getFeaturedProduct$(): Observable<Product[]> {
    return this.productDbService.getByQuery({ type: 'product', query: { featured: true } })
      .pipe(map(p => {
        // console.log(p);
        const products = p.map(newProductList);
        p['products'] = products;
        return p;
      }));
  }


  getProducts$(options): Observable<DocumentList[]> {
    const paginator = { pageIndex: 0, pageSize: 6 };
    const sort = { active: 'title.key', direction: 'asc' };

    let filter = {} as any;

    if (options.type === 'document') {
      filter = {
        ...filter,
        type: [{ value: ProductType.PRINT, op: '===', key: 'type' }],
        internal: [{ value: true, op: "!==", key: 'internal' }]
      }
    }
    if (options.type === 'product') {
      filter = {
        ...filter,
        type: [{ value: ProductType.STORE, op: '===', key: 'type' }],
      }
    }
    if ('featured' in options.query) {
      filter = {
        ...filter,
        featured: [{ value: options.query.featured, op: "===", key: 'featured' }],
      }
    }

    if ('vendor' in options.query) {
      filter = {
        ...filter,
        vendor_id: [{ value: options.query.vendor, op: "===", key: 'vendor.id' }],
      }
    }
    if ('status' in options.query) {
      filter = {
        ...filter,
        status: [{ value: options.query.status, op: "===", key: 'status' }],
      }
    }
    if ('excludeProduct' in options.query) {
      filter = {
        ...filter,
        id: [{ value: options.query.excludeProduct, op: "!==", key: 'id' }],
      }
    }

    console.log("FFILTER", filter);
    return from(this.productDbService.query(paginator, sort, filter)).pipe(
      map(res => {
        return (res?.data ?? []).map(PrintProduct.newDocumentList)
      }),
    )
  }


  query(paginator, sort, filter) {

    return from(this.productDbService.query(paginator, sort, filter)
        .then(response => {
          // this.count = response._meta.count;
          // this.loadingSubject.next(false);
          return response;
        })
      // .catch(err =>{
      //   // this.loadingSubject.next(false);
      //   return err;
      //   console.error("Products data Source Fail", err)
      // })
    )

  }
}
